export const MAILS_LOG_TABLE_HEADER = [
    {
        text: 'ID',
        value: 'id',
        width: '75px',
        align: 'center',
        sortable: true
    },
    {
        text: 'Admin',
        value: 'admin',
        // width: '350px',
        sortable: false
    },
    {
        text: 'Address',
        value: 'address',
        // width: '200px',
        sortable: false
    },
    {
        text: 'Recipients',
        value: 'recipients',
        // width: '250px',
        sortable: false
    },
    {
        text: 'Recipients count',
        value: 'recipients_count',
        // width: '150px',
        sortable: true
    },
    {
        text: 'Date',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Description',
        value: 'description',
        sortable: false
    }
]

export const MAILS_LOG_TABLE_HEADER_TOTAL = [
    {
        text: 'Total',
        value: 'total',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    }
]

export const MAILING_LOG_MEMBERS_TABLE_HEADER = [
    {
        text: 'In-x',
        value: 'index',
        width: '75px'
    },
    {
        text: 'Address',
        value: 'address',
        sortable: true
    }
]
