<template>
    <Viewer
        :title="'Recipients details'"
        @closeSidePanel="close"
    >
        <template #body>
            <v-data-table
                :headers="tableHeader"
                :items="recipients"
                item-key="address"
                :loading="false"
                hide-default-footer
                calculate-widths
                class="mt-10"
                must-sort
                :sort-by="'name'"
                :sort-desc="false"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading && recipients.length === 0"
                        :column-count="tableHeaderLength"
                        :per-page="10"
                    />
                    <tbody
                        class="bids_scroll"
                    >
                        <tr
                            v-for="(item, index) in items"
                            :key="item"
                            class="hovered-row"
                        >
                            <td>
                                {{ index +1 }}
                            </td>
                            <td>
                                {{ item }}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <div
                v-if="!recipients.length && !loading"
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No data found
                </p>
            </div>
        </template>
    </Viewer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import Viewer from '@/components/SidePanels/Viewer'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import {
    MAILING_LOG_MEMBERS_TABLE_HEADER
} from '@/constants/MailingLog'
import {
    ACTION_GET_MAILS_LOG_FETCH
} from '@/store/modules/mailing/action-types';

const {
    mapActions: mapMailingActions
} = createNamespacedHelpers('mailing');

export default {
    name: 'MailingLogViewer',
    components: {
        Viewer,
        TableSkeleton
    },
    props: {
        id: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            recipients: [],
            loading: true
        }
    },
    computed: {
        tableHeader() {
            return MAILING_LOG_MEMBERS_TABLE_HEADER
        },
        tableHeaderLength() {
            return MAILING_LOG_MEMBERS_TABLE_HEADER.length
        }
    },
    async created() {
        await this.fetchMailing()
    },
    methods: {
        ...mapMailingActions({
            ACTION_GET_MAILS_LOG_FETCH
        }),
        close() {
            this.$emit('close')
        },
        async fetchMailing() {
            try {
                this.loading = true
                const data = await this[ACTION_GET_MAILS_LOG_FETCH]({
                    id: this.id
                })
                this.recipients = data.recipients
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
