<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <div class="d-flex align-center">
                    <v-select
                        v-model="template"
                        :items="templates"
                        label="Template"
                        class="my-3"
                        outlined
                        :rules="[rules.required]"
                        required
                        hide-details
                    />
                    <v-icon
                        v-if="!!template"
                        class="ml-4"
                        @click="preview"
                        v-text="'mdi-eye'"
                    />
                </div>
            </v-form>
            <mail-preview
                v-if="showPreviewModal"
                :title="templateTitle"
                :html="templateHtml"
                @closeModal="onClosePreviewModal"
            />
            <table-loader
                v-if="isLoading"
            />
        </template>
    </modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import Modal from '@/components/shared/Modals/Modal'
import {
    ACTION_GET_MAILING_TEMPLATES_LIST,
    ACTION_GET_MAILING_TEMPLATE
} from '@/store/modules/mailing/action-types';
import MailPreview from '@/components/shared/Modals/MailPreview'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'

const {
    mapActions: mapMailingActions
} = createNamespacedHelpers('mailing');
export default {
    name: 'ModalSendMailing',
    components: {
        Modal,
        MailPreview,
        TableLoader
    },
    props: {
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            valid: false,
            showModal: true,
            templates: [],
            template: '',
            showPreviewModal: false,
            templateHtml: '',
            templateTitle: '',
            isLoading: false,
            rules: {
                required: (value) => !!value || 'Required.'
            }
        }
    },
    async created() {
        await this.getTemplatesList()
    },
    methods: {
        ...mapMailingActions({
            ACTION_GET_MAILING_TEMPLATES_LIST,
            ACTION_GET_MAILING_TEMPLATE
        }),
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        async getTemplatesList() {
            try {
                this.isLoading = true
                const templates = await this[ACTION_GET_MAILING_TEMPLATES_LIST]()
                this.templates = templates.map((item) => ({
                    text: `${item.title} for ${item.domain}`,
                    value: item.id
                }))
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        template_id: this.template
                    })
                }
            })
        },
        async preview() {
            await this.getPreviewHtml()
            this.showPreviewModal = true
        },
        async getPreviewHtml() {
            try {
                this.isLoading = true
                const { html, title, domain } = await this[ACTION_GET_MAILING_TEMPLATE]({ id: this.template })
                this.templateHtml = html
                this.templateTitle = `${title} for ${domain}`
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        onClosePreviewModal() {
            this.showPreviewModal = false
        }
    }
}
</script>

<style>

</style>
