export const TABLE_HEADER = [
    {
        text: 'Key',
        value: 'key',
        width: 250,
        sortable: false
    },
    {
        text: 'Value',
        value: 'value',
        sortable: false,
        width: 320
    },
    {
        text: '',
        value: '',
        sortable: false
    }
]

export const TABLE_HEADER_SITES = [
    {
        text: 'Name',
        value: 'name',
        width: 250,
        sortable: false
    },
    {
        text: 'Variables',
        value: 'settings',
        sortable: false,
        width: 600
    },
    {
        text: '',
        value: '',
        sortable: false
    }
]
