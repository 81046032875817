<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Edit support ticket</span>
        </template>
        <template #body>
            <v-form>
                <v-col cols="12">
                    <v-select
                        v-model="data.status_priority"
                        :items="priorities"
                        hide-details
                        label="Priority"
                        outlined
                    >
                        <template
                            slot="selection"
                            slot-scope="{ item }"
                        >
                            <img
                                v-if="item.value !== 'all'"
                                class="mr-2"
                                :src="require(`@images/svg/${getIcon(item.value)}`)"
                                :alt="item.value"
                            >
                            {{ item.text }}
                        </template>
                        <template
                            slot="item"
                            slot-scope="{ item }"
                        >
                            <img
                                v-if="item.value !== 'all'"
                                class="mr-2"
                                :src="require(`@images/svg/${getIcon(item.value)}`)"
                                :alt="item.value"
                            >
                            {{ item.text }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="data.subject"
                        label="subject"
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="data.msgtype"
                        :items="msgtypes"
                        color="accent"
                        outlined
                        hide-details
                        label="types"
                    />
                </v-col>
                <v-col
                    v-if="data.msgtype === 'complaint'"
                    cols="12"
                >
                    <v-select
                        v-model="data.msgcategory"
                        :items="msgproblems"
                        color="accent"
                        outlined
                        hide-details
                        label="problems"
                    />
                </v-col>
                <v-col
                    v-if="data.msgtype === 'complaint'"
                    cols="12"
                >
                    <v-select
                        v-model="data.msgresolution"
                        :items="msgresolutions"
                        color="accent"
                        outlined
                        hide-details
                        label="category"
                    />
                </v-col>
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'
import {
    problems, resolutions, types
} from '@/constants/Support/support-types'
import {
    SUPPORT_PRIORITY
} from '@/constants/Support'

export default {
    components: {
        Modal
    },
    props: {
        ticket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            data: {
                status_priority: '',
                subject: '',
                msgtype: '',
                msgcategory: '',
                msgresolution: ''
            }
        }
    },
    computed: {
        priorities() {
            return SUPPORT_PRIORITY
        },
        msgtypes() {
            return types
        },
        msgresolutions() {
            return resolutions
        },
        msgproblems() {
            return problems
        }
    },
    // watch: {
    //     data.msgtype
    // },
    created() {
        this.data.status_priority = this.ticket.status_priority
        this.data.subject = this.ticket.subject
        this.data.msgtype = this.ticket.msgtype
        this.data.msgcategory = this.ticket.msgcategory
        this.data.msgresolution = this.ticket.msgresolution
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', this.data)
        },
        getIcon(str) {
            switch (str) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return ''
            }
        }
    }
}
</script>

<style>

</style>
