/* eslint-disable class-methods-use-this */
import Api from '@/helpers/api/index.js'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'

class ForumService {
    async getList(payload) {
        const { data } = await Api.get('/api/support/tickets/forum/list', removeEmptyParamsFromRequest({ ...payload }), {}, false);
        return data
    }

    async fetchForum(threadid) {
        const { data } = await Api.get('/api/support/tickets/forum/fetch-ticket', { ...threadid });
        return data
    }

    async sendMessage(payload) {
        const { data } = await Api.post('/api/support/tickets/forum/message/create', { ...payload });
        return data
    }

    async createForum(payload) {
        const { data } = await Api.post('/api/support/tickets/forum/create', { ...payload });
        return data
    }
}

export const forumService = new ForumService()
