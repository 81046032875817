export const MAILING_TEMPLATES_TABLE_HEADER = [
    {
        text: 'ID',
        value: 'id',
        width: '75px',
        align: 'center',
        sortable: true
    },
    {
        text: 'Title',
        value: 'title',
        sortable: true
    },
    {
        text: 'Subject',
        value: 'subject',
        sortable: true
    },
    {
        text: 'Description',
        value: 'description',
        sortable: true
    },
    {
        text: 'Created at',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Domain',
        value: 'domain',
        sortable: true,
        width: '90px'
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const MAILING_TEMPLATES_TOOLBARS = [
    {
        name: 'delete',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Delete template',
            description: 'Do you want to delete this template?',
            success_message: 'Template was deleted'
        },
        icon: 'mdi-close'
    }
]

export const MAILING_MEMBERS_TABLE_HEADER = [
    {
        text: 'Name',
        value: 'name',
        sortable: true
    },
    {
        text: 'Address',
        value: 'address',
        sortable: true
    }
]
