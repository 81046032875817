var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper py-5 mails-viewer"},[_c('v-container',{staticClass:"white rounded",attrs:{"fluid":""}},[(_vm.search_fields)?_c('the-main-filter',{attrs:{"search-fields":_vm.search_fields,"search-values":_vm.searchOptions},on:{"filterTable":_vm.onFilterTable}},[_c('template',{slot:"left_panel"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.goToCreate()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Create ")],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1",class:hover ? 'accent--text text-decoration-underline' : 'black--text',attrs:{"to":{name: 'mailing-list'}}},[_vm._v(" Mailing List ")])]}}],null,false,1420417954)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1",class:hover ? 'accent--text text-decoration-underline' : 'black--text',attrs:{"to":{name: 'mailing-templates'}}},[_vm._v(" Mailing Templates ")])]}}],null,false,1589024770)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1",class:hover ? 'accent--text text-decoration-underline' : 'black--text',attrs:{"to":{name: 'mails-log'}}},[_vm._v(" Mailing Log ")])]}}],null,false,4016291665)})],1)],2):_vm._e(),_c('v-data-table',{staticClass:"mt-4",attrs:{"must-sort":"","headers":_vm.tableHeader,"items":_vm.getterEmailsList,"item-key":"id","loading":false,"hide-default-footer":"","calculate-widths":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((_vm.tableHeaderTotal),function(h){return _c('th',{key:h.value},[(h.value === 'total')?[_c('div',{staticClass:"d-flex align-center justify-end py-2"},[_c('div',{},[_c('p',{staticClass:"mb-0 grey--text"},[_vm._v(" Total: ")]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(0))])])])])]:_vm._e()],2)}),0)])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:index,staticClass:"cursor--pointer hovered-row",on:{"click":function($event){return _vm.getMail(item.id)}}},[_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('td',{staticClass:"td_index"},[_c('domain-favicon',{attrs:{"domain":item.domain}})],1),_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.subject)+" ")])]),_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.delay)+" ")])]),_c('td',{staticClass:"td_index"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.priority)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(item.created_at && _vm.$date.fromWithText(item.created_at))+" ")]),_c('information-tooltip',[_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"mb-0 text-body-2"},[_vm._v(" Created: ")]),_c('p',{staticClass:"mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(item.created_at ? _vm.$date.fullDate(item.created_at) : '—')+" ")])]),_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"mb-0 text-body-2"},[_vm._v(" Updated: ")]),_c('p',{staticClass:"mb-0 d-flex align-center  text-subtitle-2 font-weight-regular"},[_vm._v(" "+_vm._s(item.updated_at ? _vm.$date.fullDate(item.updated_at) : '—')+" ")])])])],1)])])})],2),(_vm.isLoading)?_c('table-loader'):_vm._e(),(_vm.isLoading && _vm.listLength === 0)?_c('table-skeleton',{attrs:{"per-page":10,"column-count":_vm.tableHeaderLength}}):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }