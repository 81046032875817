<template>
    <div
        class="table_message"
        style="cursor: pointer"
    >
        <div
            class="d-flex message"
        >
            <div
                class="d-flex mr-2"
            >
                <avatar-rolles
                    v-if="last_message.author"
                    :access-status="authorType"
                    :firstname="last_message.author.firstname"
                    :lastname="last_message.author.lastname"
                    :avatar-url="last_message.author.avatar_url"
                    :avatar-fallback="last_message.author.avatar_fallback"
                    :is-accent="isByClient"
                    avatar-size="45"
                />
                <v-avatar
                    v-else
                    color="error"
                    size="45"
                >
                    <div class="white--text">
                        Error
                    </div>
                </v-avatar>
            </div>
            <div class="w-full">
                <p
                    v-if="last_message.author"
                    class="gray--text mb-0 w-full d-flex"
                >
                    <span
                        v-if="last_message.author.firstname"
                        class="text-subtitle-2 mr-auto"
                    >
                        {{ last_message.author.firstname }} - ({{ last_message.author.type }})
                    </span>
                    <span
                        v-else
                        class="text-subtitle-2 mr-auto"
                    >
                        {{ last_message.author.type }}
                    </span>
                </p>
                <div :class="messageClasses">
                    <div class="d-flex">
                        <p class="break-word mb-0">
                            {{ last_message.details }}
                        </p>
                    </div>
                </div>
                <span class="mr-5 grey--text text-subtitle-2 text-body-1">{{ $date.dateFromNow(last_message.created_at) }}</span>
                <span class="grey--text text-body-1">{{ $date.humanizeDate(last_message.created_at) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    name: 'SupportLastReplyBy',
    components: {
        AvatarRolles
    },
    props: {
        last_message: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        authorType() {
            return this.last_message.author.type.toUpperCase()
        },
        isByClient() {
            if (this.last_message.author.type !== 'Admin') {
                return true
            }
            return false
        },
        messageClasses() {
            return {
                'message__data w-full py-5 px-5 my-2': true,
                'message__data--accent': this.isLastClient
            }
        }
    }
}
</script>
