<template>
    <div
        style="min-width: 300px"
        class="px-4"
    >
        <div
            class="d-flex flex-wrap align-center justify-space-between mb-2"
        >
            <div class="d-flex flex-wrap w-full align-center mb-2">
                <status-colored
                    v-if="data.types.type"
                    :type="data.types.type"
                    class="mb-2 mr-2"
                />
                <status-colored
                    v-if="data.types.category"
                    :type="data.types.category"
                    class="mb-2 mr-2"
                />
                <status-colored
                    v-if="data.types.resolution"
                    :type="data.types.resolution"
                    class="mb-2 mr-2"
                />
            </div>
            <v-hover
                v-slot="{ hover }"
            >
                <router-link
                    :to="{
                        name: 'support-details',
                        params: {
                            id: data.ticketid,
                            type: typeTicket
                        }
                    }"
                    class="text-subtitle-2 black--text text-decoration-none d-flex align-center transition-fast-in-fast-out"
                    :class="hover ? 'info--text' : 'black--text'"
                >
                    {{ data.subject || 'EMPTY' }}

                    <v-tooltip
                        v-if="data.ticketid"
                        top
                        eager
                        max-width="480px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                class="messages_counter"
                                v-on="on"
                            >
                                {{ messagesCount }}
                                <span
                                    v-if="unreadMessagesCount > 0"
                                    class="messages_counter--unread"
                                >
                                    {{ unreadMessagesCount }}
                                </span>
                            </span>
                        </template>
                        <div class="">
                            <p class="mb-1">
                                Messages: {{ messagesCount }}
                            </p>
                            <p class="mb-1">
                                Unread messages: {{ unreadMessagesCount }}
                            </p>
                        </div>
                    </v-tooltip>

                    <v-icon
                        v-if="data.chk_callback"
                        color="success"
                        class="ml-1"
                    >
                        mdi-phone
                    </v-icon>
                </router-link>
            </v-hover>

            <div class="d-flex flex-column">
                <v-tooltip
                    top
                    open-on-click
                    :open-on-hover="false"
                    color="primary"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="primary"
                            class="ml-2"
                            v-bind="attrs"
                            @click="on.click"
                            @blur="on.blur"
                        >
                            mdi-barcode
                        </v-icon>
                    </template>
                    <div>
                        <div class="d-flex align-center">
                            <a
                                class="font-weight-medium white--text text-decoration-none ml-auto"
                                @click="goToDetails('ticket')"
                            >
                                {{ data.ticketid }}
                                <copy-to-click-board
                                    class="d-inline-flex"
                                    :copy-string="data.ticketid"
                                    icon-color="white"
                                    success-mesage="The ticket ID is copied."
                                    icon
                                />
                            </a>
                        </div>
                        <div class="d-flex align-center">
                            <a
                                v-if="data.orderid"
                                class="font-weight-medium white--text text-decoration-none"
                                @click="goToDetails('order')"
                            >
                                {{ data.orderid }}
                                <copy-to-click-board
                                    class="d-inline-flex"
                                    :copy-string="data.orderid"
                                    success-mesage=" The order ID is copied."
                                    icon
                                />
                            </a>
                        </div>
                    </div>
                </v-tooltip>
            </div>
        </div>

        <v-divider class="my-4" />
        <div
            @click="openChatModal({modal: toolbars[3].modal, actionName: toolbars[3].name})"
        >
            <support-last-reply-by
                :last_message="data.last_message"
            />
        </div>
    </div>
</template>

<script>
import SupportLastReplyBy from '@/components/Support/SupportLastReplyBy'
import StatusColored from '@/components/shared/Helpers/StatusColored'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard';

export default {
    components: {
        SupportLastReplyBy,
        StatusColored,
        CopyToClickBoard
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        toolbars: {
            type: Array,
            required: true
        }
    },
    computed: {
        typeTicket() {
            return this.$route.name.includes('writer') ? 'writer' : 'client'
        },
        messagesCount() {
            return this.data.counters.messages_count
        },
        unreadMessagesCount() {
            if (this.typeTicket === 'writer') {
                return this.data.counters.unread_messages_by_writer_count
            }
            return this.data.counters.unread_messages_by_client_count
        }
    },
    methods: {
        openChatModal(payload) {
            this.$emit('actionRow', this.data, payload)
        },
        goToDetails(type) {
            switch (type) {
            case 'ticket':
                this.$router.push({
                    name: 'support-details',
                    params: {
                        id: this.data.ticketid,
                        type: this.typeTicket
                    }
                })
                break;
            case 'order':
                this.$router.push({
                    name: 'order-details',
                    params: {
                        id: this.data.orderid
                    }
                })
                break;
            default:
                break;
            }
        }
    }
}
</script>

<style lang="scss">
    .messages_counter{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 35px;
        width: 35px;
        height: 35px;
        margin-left: 10px;
        border-radius: 50%;
        background: rgba(25, 118, 210, 0.1);
        color: #1976d2;

        &--unread{
            width: 15px;
            height: 15px;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            position: absolute;
            top: 0;
            right: -7.5px;
            background: rgb(39, 174, 96);
            border-radius: 50%;
            color: #fff;
        }
    }
</style>
