<template>
    <div class="wrapper py-5 mails-viewer">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="goToCreate()"
                    >
                        <v-icon
                            right
                            dark
                            class="mr-2"
                        >
                            mdi-plus
                        </v-icon>
                        Create
                    </v-btn>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mailing-list'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing List
                        </router-link>
                    </v-hover>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mailing-templates'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing Templates
                        </router-link>
                    </v-hover>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mails-log'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing Log
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>
            <v-data-table
                must-sort
                :headers="tableHeader"
                :items="getterEmailsList"
                item-key="id"
                :loading="false"
                hide-default-footer
                calculate-widths
                class="mt-4"
            >
                <template #header="{ }">
                    <thead
                        class="v-data-table-header"
                    >
                        <tr>
                            <th
                                v-for="h in tableHeaderTotal"
                                :key="h.value"
                            >
                                <template v-if="h.value === 'total'">
                                    <div class="d-flex align-center justify-end py-2">
                                        <div class="">
                                            <p class="mb-0 grey--text">
                                                Total:
                                            </p>
                                            <p class="mb-0">
                                                <b>{{ 0 }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <!-- <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    /> -->
                    <tbody>
                        <template>
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                                class="cursor--pointer hovered-row"
                                @click="getMail(item.id)"
                            >
                                <!-- Index -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.id }}
                                    </p>
                                </td>
                                <!-- domain -->
                                <td class="td_index">
                                    <domain-favicon
                                        :domain="item.domain"
                                    />
                                </td>
                                <!-- title -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.title }}
                                    </p>
                                </td>
                                <!-- description -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.description }}
                                    </p>
                                </td>
                                <!-- subject -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.subject }}
                                    </p>
                                </td>
                                <!-- delay -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.delay }}
                                    </p>
                                </td>
                                <!-- priority -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.priority }}
                                    </p>
                                </td>
                                <!-- DATE CREATE -->
                                <td>
                                    <div class="d-flex align-center">
                                        <p class="mb-0">
                                            {{ item.created_at && $date.fromWithText(item.created_at) }}
                                        </p>
                                        <information-tooltip>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Created:
                                                </p>
                                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                    {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                                </p>
                                            </div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Updated:
                                                </p>
                                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                    {{ item.updated_at ? $date.fullDate(item.updated_at) : '—' }}
                                                </p>
                                            </div>
                                        </information-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <table-loader
                        v-if="isLoading"
                    />
                    <table-skeleton
                        v-if="isLoading && listLength === 0"
                        :per-page="10"
                        :column-count="tableHeaderLength"
                    />
                </template>
            </v-data-table>
            <!-- <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterFeedbacksList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            /> -->
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
    ACTION_GET_EMAILS_LIST,
    ACTION_GET_SELECT_EMAIL,
    ACTION_CREATE_OR_UPDATE
} from '@/store/modules/emailViewer/action-types';

import { eventBus } from '@/helpers/event-bus/'
import {
    MAILS_VIEWER_TABLE_HEADER, MAILS_VIEWER_TABLE_HEADER_TOTAL
} from '@/constants/MailsViewer'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import InformationTooltip from '@/components/shared/InformationTooltip'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

const {
    mapActions: mapFeedbacksActions
} = createNamespacedHelpers('emailViewer');

export default {
    components: {
        InformationTooltip,
        TheMainFilter,
        TableLoader,
        TableSkeleton,
        DomainFavicon
    },
    data() {
        return {
            list: [],
            html: null,
            selectId: null,
            isLoading: false,
            search_fields: {
                datepicker: {
                    cols: 12,
                    value: 'datepicker'
                },
                domain: {
                    value: 'domain',
                    label: 'Domain',
                    list: [
                        {
                            text: 'All',
                            value: 'all'
                        },
                        {
                            text: 'customessaymeister.com',
                            value: 'customessaymeister.com'
                        },
                        {
                            text: 'dreamessays.com',
                            value: 'dreamessays.com'
                        },
                        {
                            text: 'solidpapers.com',
                            value: 'solidpapers.com'
                        },
                        {
                            text: 'genuinewriting.com',
                            value: 'genuinewriting.com'
                        },
                        {
                            text: 'professays.com',
                            value: 'professays.com'
                        },
                        {
                            text: 'paper.coach',
                            value: 'paper.coach'
                        }
                    ]
                },
                search_by: {
                    value: 'search_by',
                    label: 'Search by field',
                    list: [
                        {
                            text: 'Domain',
                            value: 'domain'
                        },
                        {
                            text: 'Priority',
                            value: 'priority'
                        },
                        {
                            text: 'Delay',
                            value: 'delay'
                        },
                        {
                            text: 'Description',
                            value: 'description'
                        },
                        {
                            text: 'Title',
                            value: 'title'
                        },
                        {
                            text: 'Subject',
                            value: 'subject'
                        }
                    ]
                }
                // sort_by: {
                //     value: 'sort_by',
                //     label: 'Sort by fields',
                //     list: [
                //         {
                //             text: 'Created at',
                //             value: 'created_at'
                //         },
                //         {
                //             text: 'Updated at',
                //             value: 'updated_at'
                //         },
                //         {
                //             text: 'ID',
                //             value: 'id'
                //         },
                //         {
                //             text: 'Domain',
                //             value: 'domain'
                //         },
                //         {
                //             text: 'Priority',
                //             value: 'priority'
                //         },
                //         {
                //             text: 'Delay',
                //             value: 'delay'
                //         },
                //         {
                //             text: 'Description',
                //             value: 'description'
                //         },
                //         {
                //             text: 'Title',
                //             value: 'title'
                //         },
                //         {
                //             text: 'Subject',
                //             value: 'subject'
                //         }
                //     ]
                // }
            },
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            }
        };
    },
    computed: {
        ...mapGetters('emailViewer', [
            'getterEmailsList'
        ]),
        tableHeader() {
            return MAILS_VIEWER_TABLE_HEADER
        },
        tableHeaderTotal() {
            return MAILS_VIEWER_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return MAILS_VIEWER_TABLE_HEADER.length + 1
        },
        listLength() {
            return this.list.length
        }
    },
    created() {
        // this.$nextTick(() => {
        //     window.scroll(0, this.savedScroll.y);
        // })
        // this.searchOptions = {
        //     ...this.searchOptions,
        //     ...this.queryObject
        // }
        this.getMailsList()
    },
    methods: {
        ...mapFeedbacksActions({
            ACTION_GET_EMAILS_LIST,
            ACTION_GET_SELECT_EMAIL,
            ACTION_CREATE_OR_UPDATE
        }),
        async getMailsList() {
            // if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
            //     this.$router.replace({ query: { ...this.searchOptions } })
            // }
            this.isLoading = true
            try {
                await this[ACTION_GET_EMAILS_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        getMail(id) {
            this.$router.push(`/settings/mails-edit/${id}`)
        },
        goToCreate() {
            this.$router.push('/settings/mails-create')
        },
        onFilterTable(payload) {
            const newObj = Object.entries(payload).reduce((acc, curr) => {
                acc[curr[0]] = this.searchOptions[curr[0]] || ''
                return acc
            }, {})
            if (JSON.stringify(payload) !== JSON.stringify(newObj)) {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
                this.getMailsList()
            }
        }
    }
}
</script>

<style lang="scss">
    .mails-viewer {
        .cursor--pointer {
            cursor: pointer;
        }
        &_window {
            width: 100%;
        }
        &_example {
            display: flex;
            justify-content: center;
            width: 100%;
            max-height: 600px;
            overflow-y: scroll;
        }
        &_editor {
            width: 100%;
            max-width: 60%;
            max-height: 580px;
            min-height: 580px;
            margin-left: 20px;
            overflow-y: scroll;
            flex-grow: 1;
            .v-text-field__details {
                display:  none;
            }
            .v-input__slot {
                margin-bottom: 0;
            }
        }
    }
</style>
