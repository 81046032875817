<template>
    <div class="wrapper py-5 writers-page">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterClientPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <div class="d-flex justify-space-between align-center w-full">
                        <the-filter-preset
                            v-if="!getterPresetsLoading"
                            :active-preset="searchOptions.preset"
                            :presets="getterClientPresets"
                            @update:shortcat="updateShortcats"
                        />
                        <v-progress-circular
                            v-else
                            :size="30"
                            :width="4"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </template>
                <template slot="button">
                    <v-btn
                        color="accent"
                        class="py-5"
                        small
                        @click="onShowCreateTicket"
                    >
                        Create Ticket
                    </v-btn>
                </template>
            </the-main-filter>

            <support-table
                v-model="selected"
                :table-items="getterSupportList"
                :total="getterTotal"
                :current-page="getterCurrentPage"
                :last-page="getterLastPage"
                :is-first-loading="isFirstLoading"
                :loading="getterSupportLoading"
                :per_page="searchOptions.per_page"
                :current-item="currentItem"
                @selected="onSelected"
                @paginate="onPaginate"
                @sortTable="onSortTable"
                @actionRow="onActionRow"
                @updatePerpage="onFilterTable"
            />

            <toolbar-menu
                v-if="selected.length"
                :toolbars="toolbars"
                @toolbarActivated="onToolbarActivated"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :loading="loadingInModal"
                :description="modal.description"
                :input-label="modal.inputLabel"
                :client_id="clientId"
                :ticket="currentItem"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
            <the-chat-side-bar
                v-if="showChat && showPanel"
                :chat="getterChat"
                :chat-loading="getterChatLoading"
                :ticket-id="currentItem.ticketid"
                :order-id="currentItem.orderid"
                :loading="isMessageSending"
                @closeChat="onCloseChat"
                @sendChatMessage="onSendChatMessage"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import SupportTable from '@/components/Support/SupportTable'
import ToolbarMenu from '@/components/shared/Tables/ToolbarMenu'

// MODALS
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalSupportEdit from '@/components/shared/Modals/ModalSupportEdit'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalCreateTicketClient from '@/components/shared/Modals/ModalCreateTicketClient'
import ModalLinkToOrder from '@/components/shared/Modals/ModalLinkToOrder'

import Helper from '@/helpers/functions'

import {
    ACTION_CLIENT_TRANSFER_TICKET,
    ACTION_CLOSE_TICKET,
    ACTION_CLOSE_TICKETS_MASS,
    ACTION_CREATE_TICKET,
    ACTION_CREATE_TICKET_WRITER,
    ACTION_GET_CLIENT_PRESETS,
    ACTION_GET_SUPPORT_LIST,
    ACTION_SEND_MESSAGE,
    ACTION_START_LIVE_UPDATE,
    ACTION_STOP_LIVE_UPDATE,
    ACTION_UPDATE_TICKET,
    ACTION_GET_SUPPORT_CHAT,
    ACTION_RESET_STATE,
    ACTION_BIND_ORDER_TO_TICKET
} from '@/store/modules/support/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import {
    SUPPORT_MASS_TOOLBARS
} from '@/constants/Support'
import TheChatSideBar from '@/components/Support/TheChatSideBar';
import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('support');

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

export default {
    components: {
        TheMainFilter,
        TheFilterPreset,
        SupportTable,
        ModalTextarea,
        ModalTransfer,
        ModalCreateTicketClient,
        ModalSupportEdit,
        ModalAlert,
        ToolbarMenu,
        TheChatSideBar,
        ModalLinkToOrder
    },
    data() {
        return {
            isFirstContentLoaded: false,
            isFirstLoading: true,
            savedScroll: { x: 0, y: 0 },
            timerID: null,
            loading: false,
            selected: [],
            searchOptions: {
                page: 1,
                per_page: 10,
                status_priority: '',
                status_support: '',
                search_for: '',
                search_by: 'ticketid',
                sort_by: 'last_replied_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            },
            search_fields: null,
            modal: {},
            currentItem: {},
            actionName: '',
            showChat: false,
            isMessageSending: false,
            loadingInModal: false,
            showPanel: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterSupportList',
            'getterLastPage',
            'getterTotal',
            'getterCurrentPage',
            'getterClientPresets',
            'getterChat',
            'getterChatLoading',
            'getterSupportLoading',
            'getterPresetsLoading'
        ]),
        clientId() {
            return this.currentItem?.respondent?.id
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        toolbars() {
            return SUPPORT_MASS_TOOLBARS
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        }
    },
    watch: {
        showPanel(val) {
            Helper.lockHtml(val)
        }
    },
    metaInfo: {
        title: 'Support client'
    },
    async activated() {
        setTimeout(() => {
            window.scroll(0, this.savedScroll.y);
        }, 0);
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        if (!this.isFirstLoading) {
            requests.push(this.getListSupport())
        }
        await Promise.allSettled(requests)
        this.timerID = setTimeout(async () => {
            await this[ACTION_START_LIVE_UPDATE]({ ...this.searchOptions })
        }, 1000 * 30);
    },
    deactivated() {
        clearTimeout(this.timerID)
        this[ACTION_STOP_LIVE_UPDATE]()
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        next()
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'support-details') {
            next()
        } else {
            next(async (vm) => {
                await vm[ACTION_RESET_STATE]()
            })
        }
    },
    methods: {
        async get_search_fields() {
            try {
                this.search_fields = await searchFieldsHelper.getSearchField('client-support')
            } catch (error) {
                this.search_fields = {}
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        ...mapAdminActions({
            ACTION_RESET_STATE,
            ACTION_GET_SUPPORT_LIST,
            ACTION_SEND_MESSAGE,
            ACTION_UPDATE_TICKET,
            ACTION_CREATE_TICKET,
            ACTION_CLOSE_TICKET,
            ACTION_CREATE_TICKET_WRITER,
            ACTION_START_LIVE_UPDATE,
            ACTION_STOP_LIVE_UPDATE,
            ACTION_GET_CLIENT_PRESETS,
            ACTION_CLIENT_TRANSFER_TICKET,
            ACTION_CLOSE_TICKETS_MASS,
            ACTION_GET_SUPPORT_CHAT,
            ACTION_BIND_ORDER_TO_TICKET
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        // TABLE LOGIC
        onToolbarActivated(payload) {
            this.actionName = payload.type
            this.modal = payload.modal
        },
        async getListSupport() {
            try {
                this.loading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                await Promise.all([this[ACTION_GET_CLIENT_PRESETS](), this[ACTION_GET_SUPPORT_LIST]({ ...this.searchOptions })])
                this.isFirstLoading = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async onFilterTable(payload) {
            if (this.isFirstContentLoaded === false) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getListSupport()
            this.isFirstContentLoaded = true
        },
        async onPaginate(page) {
            this.searchOptions.page = page
            await this.getListSupport()
        },
        async onSortTable(flag, option) {
            this.searchOptions = {
                ...this.searchOptions,
                ...option,
                page: 1
            }
            if (!flag) {
                await this.getListSupport()
            }
        },
        onSelected(data) {
            this.selected = data
        },
        // ACTIONS WITH TABLE
        async onSendChatMessage(payload) {
            this.isMessageSending = true
            try {
                await this[ACTION_SEND_MESSAGE]({ payload, item: this.currentItem })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isMessageSending = false
            }
        },
        async updateTicket(payload) {
            await this[ACTION_UPDATE_TICKET](payload)
        },
        async linkToOrder(payload) {
            await this[ACTION_BIND_ORDER_TO_TICKET](payload)
            await this.getListSupport()
        },
        async onHideAsResolved(payload) {
            await this[ACTION_CLOSE_TICKET]({ id: payload.id })
        },
        async createBookmark(payload) {
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids: [this.currentItem.ticketid] })
        },
        async createBookmarkMass(payload) {
            const referenceids = this.selected.map((item) => item.ticketid)
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids })
        },
        async transferTicket({ admin_id, transfer_details }) {
            await this[ACTION_CLIENT_TRANSFER_TICKET]({ admin_id, transfer_details, ticketid: this.currentItem.ticketid })
        },
        async closeMassTicket() {
            const referenceids = this.selected.map((item) => item.ticketid)
            await this[ACTION_CLOSE_TICKETS_MASS](referenceids)
        },
        async createClientTicket(payload) {
            await this[ACTION_CREATE_TICKET](payload)
        },
        onShowCreateTicket() {
            this.modal = {
                name: 'ModalCreateTicketClient',
                success_message: 'The ticket was created'
            }
            this.actionName = 'create_ticket_client'
        },
        onActionRow(item, action) {
            this.currentItem = item
            if (action.actionName === 'chat') {
                this.onShowChat()
                return
            }
            this.actionName = action.actionName
            this.modal = action.modal
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal(payload) {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'edit':
                    await this.updateTicket({ ...payload, id: this.currentItem.ticketid, clientid: `${this.getterMainInfo.id}` })
                    break
                case 'order':
                    await this.linkToOrder(payload)
                    break
                case 'resolve_ticket':
                    await this.onHideAsResolved({ id: this.currentItem.ticketid })
                    break
                case 'transfer':
                    await this.transferTicket(payload)
                    break
                case 'bookmark':
                    await this.createBookmark(payload)
                    break
                case 'bookmark_mass':
                    await this.createBookmarkMass(payload)
                    break
                case 'close_tickets':
                    await this.closeMassTicket()
                    break
                case 'create_ticket_client':
                    await this.createClientTicket(payload)
                    break
                default:
                    break
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        },
        async onShowChat() {
            try {
                this.showChat = true
                this.showPanel = true
                await this[ACTION_GET_SUPPORT_CHAT]({ id: this.currentItem.ticketid })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCloseChat() {
            this.showChat = false
            this.showPanel = false
        }
    }
}
</script>

<style lang="scss">
    .max-w-110{
        max-width: 110px;
        word-wrap: break-word;
    }
    .v-data-table__wrapper{
        table {
            tbody {
                & > tr{
                    position: relative !important;
                    & > td {
                        padding-bottom: 30px !important;
                    }
                }
            }
        }
    }
</style>
