<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-row class="mb-5" />

            <v-data-table
                :headers="tableHeader"
                :items="sites"
                item-key="id"
                :loading="isLoading"
                :server-items-length="sites.length"
                hide-default-footer
                class="mt-10"
            >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.settings="props">
                    <v-row>
                        <v-col
                            v-for="setting in props.item.settings"
                            :key="`${setting.id}${props.item.id}`"
                            cols="5"
                        >
                            <v-btn
                                v-if="setting.key === 'project_types'"
                                color="accent"
                                @click="showSiteSettingsTypes(setting.value, props.item.id, setting.id)"
                            >
                                Change project types
                            </v-btn>
                            <v-switch
                                v-else-if="setting.type === 'boolean'"
                                color="success"
                                :input-value="+setting.value === 1"
                                :label="setting.key"
                                @change="updateSwitch({domain_id: props.item.id, setting_id: setting.id, value: +setting.value === 1 ? '0': '1'})"
                            />
                            <v-edit-dialog
                                v-else
                                :return-value.sync="setting.value"
                                large
                                persistent
                                @save="save({setting_id: setting.id, value: setting.value, domain_id: props.item.id})"
                            >
                                <div class="input_variable">
                                    <v-text-field
                                        class="mb-0"
                                        :label="setting.key"
                                        readonly
                                        :value="setting.value"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </div>
                                <template v-slot:input>
                                    <v-text-field
                                        v-model="setting.value"
                                        class="mt-4 mb-0"
                                        :label="setting.key"
                                        outlined
                                        dense
                                    />
                                </template>
                            </v-edit-dialog>
                        </v-col>
                    </v-row>
                </template>
            </v-data-table>
        </v-container>

        <SiteSettings
            v-if="showSettingsTypes"
            :types="currentTypes"
            @closeSiteSettings="closeSiteSettings"
            @saveTypes="saveTypes"
        />
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import {
    TABLE_HEADER_SITES
} from '@/constants/Settings/index.js'
import SiteSettings from '@/components/SidePanels/SiteSettings'

export default {
    components: {
        SiteSettings
    },
    data() {
        return {
            isLoading: true,
            sites: [],
            showSettingsTypes: false,
            currentTypes: null,
            siteId: null,
            settingId: null
        }
    },
    computed: {
        tableHeader() {
            return TABLE_HEADER_SITES
        }
    },
    async created() {
        await this.getSites()
    },
    methods: {
        async getSites() {
            try {
                this.isLoading = true
                const { data } = await Api.get('/api/settings/list-sites', {}, false, false)
                this.sites = data
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async save(payload) {
            await Api.post('/api/settings/set-custom', { ...payload })
            await this.getSites()
        },
        updateSwitch(payload) {
            this.save({
                ...payload
            })
        },
        async saveTypes(payload) {
            await this.save({
                domain_id: this.siteId,
                setting_id: this.settingId,
                value: JSON.stringify([...payload])
            })
        },
        showSiteSettingsTypes(value, siteId, settingId) {
            this.showSettingsTypes = true
            this.currentTypes = value
            this.siteId = siteId
            this.settingId = settingId
        },
        closeSiteSettings() {
            this.showSettingsTypes = false
            this.currentTypes = null
            this.siteId = null
            this.settingId = null
        }
    }
}
</script>
<style lang="scss" >
    .input_editable{
        display: flex;
        flex-wrap: wrap;
        .v-input {
            margin-right: 20px;
        }
    }
</style>
