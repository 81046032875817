<template>
    <div class="">
        <v-data-table
            v-model="selected"
            :headers="tableHeader"
            :items="tableItems"
            item-key="ticketid"
            :server-items-length="total"
            :loading="loading"
            hide-default-footer
            calculate-widths
            class="mt-4"
            must-sort
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:options="sortTable"
        >
            <template #header="{ }">
                <thead
                    v-if="!mobile"
                    class="v-data-table-header"
                >
                    <tr>
                        <th
                            v-for="h in prependHeader"
                            :key="h.value"
                        >
                            <template v-if="h.value === 'priority'">
                                <div class="d-flex align-center td_priority">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Tickets:
                                        </p>
                                        <p class="mb-0">
                                            <b>{{ getterSummary.total }}</b>
                                        </p>
                                    </div>
                                </div>
                            </template>

                            <template v-if="h.value === 'created_at'">
                                <div class="">
                                    <p class="mb-0 grey--text">
                                        Pages:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ Math.ceil(getterSummary.total / per_page) }}</b>
                                    </p>
                                </div>
                            </template>
                        </th>
                    </tr>
                </thead>
                <thead v-else>
                    <tr>
                        <th class="d-flex align-center">
                            <div class="d-flex align-center td_priority mr-4">
                                <div class="mr-4">
                                    <p class="mb-0 grey--text">
                                        Tickets:
                                    </p>
                                    <p class="mb-0">
                                        <b>{{ getterSummary.total }}</b>
                                    </p>
                                </div>
                            </div>
                            <div class="mr-4">
                                <p class="mb-0 grey--text">
                                    Pages:
                                </p>
                                <p class="mb-0">
                                    <b>{{ Math.ceil(getterSummary.total / per_page) }}</b>
                                </p>
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <table-skeleton
                    v-if="loading && (items && items.length === 0)"
                    :per-page="per_page"
                    :column-count="tableHeaderLength"
                />
                <table-loader
                    v-if="loading"
                />
                <tbody>
                    <tr
                        v-for="(item) in items"
                        :key="item.ticketid"
                        class="hovered-row"
                        :class="rowClasses(item)"
                        @mouseenter="selectItem(item.id)"
                        @mouseleave="unSelectItem()"
                    >
                        <!-- PRIORITY -->
                        <td class="td_priority">
                            <div class="mb-0 mt-2 d-flex align-center">
                                <img
                                    v-if="item.status_priority"
                                    :src="require(`@images/svg/${getIcon(item.status_priority)}`)"
                                    :alt="item.status_priority"
                                >
                            </div>
                        </td>
                        <!-- CREATED -->
                        <td>
                            <div class="d-flex align-center">
                                <p class="mb-0">
                                    {{ item.created_at && $date.fromWithText(item.created_at) }}
                                </p>
                                <information-tooltip>
                                    <div class="mb-0">
                                        <p class="mb-0 text-body-2">
                                            Created:
                                        </p>
                                        <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                            {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                        </p>
                                    </div>
                                </information-tooltip>
                            </div>
                        </td>
                        <!-- last replied -->
                        <td class="td_created max-w-130 break-word">
                            <p v-if="item.last_replied_at">
                                {{ $date.shortFormat(item.last_replied_at) }}
                            </p>
                        </td>
                        <!-- SUBJECT -->
                        <td
                            class="td_subject"
                        >
                            <support-subject
                                :toolbars="toolbars"
                                :data="item"
                                @actionRow="onActionRow"
                            />
                        </td>
                        <!-- CREATOR -->
                        <td>
                            <SupportTicketCreator
                                v-if="item.author"
                                :data="item"
                            />
                            <div class="d-flex flex-column mt-10">
                                <div class="">
                                    <p class="mb-1 grey--text font-weight-bold">
                                        Respondent:
                                    </p>
                                    <div v-if="item.respondent">
                                        <a
                                            class="d-flex text-decoration-none black--text"
                                            :href="linkType(item)"
                                            target="_blank"
                                        >
                                            <avatar-rolles
                                                :access-status="item.respondent.type"
                                                :firstname="item.respondent.firstname"
                                                :lastname="item.respondent.lastname"
                                                :avatar-url="item.respondent.avatar_url"
                                                :avatar-fallback="item.respondent.avatar_fallback"
                                                :is-accent="true"
                                                avatar-size="45"
                                            />
                                            <div class="ml-4">
                                                <p class="mb-0 font-weight-bold">
                                                    {{ item.respondent.firstname }}
                                                    ({{ item.respondent.type }})
                                                </p>
                                                <p class="mb-0">
                                                    ID: {{ item.respondent.id }}
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <!-- RESPONDENT -->
                        <!-- DOMAIN -->
                        <td
                            v-if="!isWritersTable"
                            class="td_doamin"
                        >
                            <domain-favicon
                                :domain="item.domain"
                            />
                        </td>
                        <!-- STATUS -->
                        <td class="td_status">
                            <status-colored
                                :type="item.status_support"
                                class="mb-2"
                            />
                        </td>

                        <td
                            @click.stop
                        >
                            <table-rows-menu
                                :toolbars="toolbars"
                                :item="item"
                                class="mt-2"
                                @menuActivated="onMenuActivated"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!tableItems.length && !loading"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No tickets found
            </p>
        </div>

        <pagination-with-perpage
            :list-lenght="tableItems.length"
            :last-page="lastPage"
            :search-options="{ page, per_page }"
            :total-visible="7"
            @updatePage="paginationPageEvent"
            @updatePerpage="onUpdatePerpage"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { nameCapitalized } from '@/helpers/capitalized/index'
import SupportSubject from '@/components/Support/SupportSubject'
import SupportTicketCreator from '@/components/Support/SupportTicketCreator'
import StatusColored from '@/components/shared/Helpers/StatusColored'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import InformationTooltip from '@/components/shared/InformationTooltip'

import {
    SUPPORT_TABLE_HEADER, SUPPORT_TOOLBARS
} from '@/constants/Support'

export default {
    components: {
        SupportSubject,
        SupportTicketCreator,
        StatusColored,
        DomainFavicon,
        PaginationWithPerpage,
        TableSkeleton,
        TableRowsMenu,
        TableLoader,
        AvatarRolles,
        InformationTooltip
    },
    props: {
        tableItems: {
            type: Array,
            reqiured: true
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        isFirstLoading: {
            type: Boolean
        },
        lastPage: {
            type: Number,
            default: 0
        },
        per_page: {
            type: Number,
            default: 10
        },
        currentItem: {
            type: Object
        }
    },
    data() {
        return {
            selected: [],
            hoveredItem: null,
            page: null,
            flag: true,
            mobile: false
        }
    },
    computed: {
        ...mapGetters('support', [
            'getterSummary'
        ]),
        tableHeader() {
            if (this.isWritersTable) {
                return SUPPORT_TABLE_HEADER.filter((item) => item.value !== 'domain')
            }
            return SUPPORT_TABLE_HEADER
        },
        prependHeader() {
            const newArr = [...SUPPORT_TABLE_HEADER]
            // newArr.unshift({ text: 'checkbox', value: 'checkbox' })
            if (this.isWritersTable) {
                return newArr.filter((item) => item.value !== 'domain')
            }
            return newArr
        },
        tableHeaderLength() {
            if (this.isWritersTable) {
                return SUPPORT_TABLE_HEADER.length
            }
            return SUPPORT_TABLE_HEADER.length + 1
        },
        toolbars() {
            return SUPPORT_TOOLBARS
        },
        sortBy() {
            return this.$route.query.sort_by || 'last_replied_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        isWritersTable() {
            return this.$route.name.includes('writer')
        },
        hideAsResolvedItem() {
            return {
                actionName: 'resolve_ticket',
                modal: {
                    name: 'ModalAlert',
                    title: 'Hide as Resolved',
                    description: 'Do you really go to close the ticket?'
                }
            }
        }
    },
    watch: {
        selected() {
            this.$emit('selected', this.selected)
        },
        tableItems: {
            handler(newVal) {
                this.selected = this.selected.map((select) => {
                    const find = newVal.find((item) => item.ticketid === select?.ticketid)
                    return find
                })
            },
            deep: true
        },
        currentPage() {
            this.page = this.currentPage
        }
    },
    created() {
        this.page = this.currentPage
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        nameCapitalized,
        selectItem(item) {
            this.hoveredItem = item
        },
        rowClasses(item) {
            return {
                'table-row--updated': item.isUpdated,
                'table-row--selected': this.selected.some((select) => select?.ticketid === item.ticketid),
                'table-row--selected-once': this.currentItem?.ticketid === item.ticketid
            }
        },
        unSelectItem() {
            this.hoveredItem = null
        },
        paginationPageEvent(e) {
            this.$emit('paginate', e)
        },
        linkType(item) {
            if (item.respondent_model === 'Admin' || item.respondent.type === 'Admin') return false
            return `${item.respondent.type.toLowerCase() === 'client' ? '/hr' : ''}/${item.respondent.type.toLowerCase()}s/${item.respondent.id}`
        },
        sortTable(e) {
            const {
                sortBy,
                sortDesc
            } = e

            this.$emit('sortTable', this.flag, {
                sort_by: sortBy[0],
                sort_order: sortDesc[0] === true ? 'ASC' : 'DESC'
            })
            this.flag = false
        },
        onActionRow(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        hideAsResolved(item) {
            this.$emit('hideAsResolved', item)
        },
        onUpdatePerpage(payload) {
            this.$emit('updatePerpage', payload)
        },
        getIcon(priority) {
            switch (priority) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return false
            }
        },
        onMenuActivated(item, modal) {
            this.$emit('actionRow', item, modal)
        }
    }

}
</script>

<style lang="scss">
    .max-w-130{
        max-width: 130px;
    }
    .td_priority {
        max-width: 30px;
    }
    .td_created{
       white-space: nowrap;
    }
    .pointer-none {
        pointer-events: none;
    }
</style>
