<template>
    <Viewer
        title="Forum"
        @closeSidePanel="closeChat"
    >
        <template #body>
            <Chat
                v-if="!isLoading"
                :chat="messages"
                :owner-roles="['by-id']"
                @sendChatMessage="onSendChatMessage"
            />
        </template>
    </Viewer>
</template>

<script>
import Viewer from '@/components/SidePanels/Viewer'
import { forumService } from '@/services/ForumService'
import Chat from '@/components/Chat/Chat'

export default {
    components: {
        Viewer,
        Chat
    },
    props: {
        threadid: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            forum: null
        }
    },
    computed: {
        messages() {
            return this.forum.inner.map((item) => ({
                ...item,
                author: {
                    ...item.admin,
                    type: 'Admin',
                    lastname: item.admin.admin_firstname,
                    firstname: item.admin.admin_lastname
                }
            }))
        }
    },
    async created() {
        this.isLoading = true
        const response = await forumService.fetchForum({ threadid: this.threadid })
        this.forum = response
        this.isLoading = false
    },
    methods: {
        onSendChatMessage(payload) {
            this.$emit('sendChatMessage', payload)
        },
        closeChat() {
            this.$emit('closeChat')
        }
    }
}
</script>

<style lang="scss">

</style>
