export const types = [
    {
        value: 'inquiry',
        text: 'Inquiry'
    },
    {
        value: 'suggestion',
        text: 'Suggestion'
    },
    {
        value: 'complaint',
        text: 'Complaint'
    },
    {
        value: 'other',
        text: 'Other'
    },
    {
        value: 'website',
        text: 'Technical issue with the website'
    }
]

export const resolutions = [
    {
        value: 'revision',
        text: 'Revision'
    },
    {
        value: 'new_writer',
        text: 'New writer'
    },
    {
        value: 'discount',
        text: 'Discount On Next Order'
    },
    {
        value: 'store_credit',
        text: 'Store credit'
    },
    {
        value: 'reply_finance',
        text: 'Reply finance'
    },
    {
        value: 'refund',
        text: 'Refund partial refund'
    },
    {
        value: 'other',
        text: 'Other'
    }
]

export const problems = [
    {
        value: 'delivery',
        text: 'Delivery: The project was delivered late'
    },
    {
        value: 'billing',
        text: 'Billing: The billing amount was incorrect'
    },
    {
        value: 'quality_guidelines',
        text: 'Quality: The project guidelines were not followed'
    },
    {
        value: 'quality_writing',
        text: 'Quality: The writing was not up to standards'
    },
    {
        value: 'content_pagecount',
        text: 'Content: The number of pages was incorrect'
    },
    {
        value: 'project_fee',
        text: 'Project fee'
    },
    {
        value: 'payout',
        text: 'Payout'
    },
    {
        value: 'other',
        text: 'Other'
    }
]
