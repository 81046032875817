<template>
    <v-slide-x-reverse-transition>
        <v-card
            v-click-outside="closeChat"
            elevation="2"
            class="chat_modal"
        >
            <div class="d-flex justify-space-between align-center w-full py-5">
                <p class="text-subtitle-1 mb-0">
                    Chat
                </p>
                <v-btn
                    icon
                    @click="closeChat"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
            </div>
            <div class="d-flex pb-5">
                <div v-if="ticketId">
                    <div class="mb-1 font-weight-light">
                        Ticket ID:
                    </div>
                    <div>
                        {{ ticketId }}
                        <copy-to-click-board
                            class="d-inline-flex"
                            :copy-string="ticketId"
                            success-mesage=" The ticket ID is copied."
                            icon
                            @click.stop
                        />
                    </div>
                </div>
                <div
                    v-if="orderId"
                    class="ml-5"
                >
                    <div class="mb-1 font-weight-light">
                        Order ID:
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'order-details',
                                params: {
                                    id: orderId
                                }
                            }"
                            target="_blank"
                        >
                            {{ orderId }}
                        </router-link>
                        <copy-to-click-board
                            class="d-inline-flex"
                            :copy-string="orderId"
                            success-mesage=" The order ID is copied."
                            icon
                        />
                    </div>
                </div>
            </div>
            <v-divider />

            <div
                v-if="chatLoading"
                class="d-flex justify-center align-center"
            >
                <v-progress-circular

                    indeterminate
                    color="primary"
                />
            </div>
            <chat
                v-else
                :chat="chat"
                :owner-roles="['Admin']"
                :loading="loading"
                :typing-message="typingMessage"
                @sendChatMessage="onSendChatMessage"
                @typing="typingListener"
            />
        </v-card>
    </v-slide-x-reverse-transition>
</template>

<script>
import Chat from '@/components/Chat/Chat'
import { createNamespacedHelpers } from 'vuex';
import { SET_SUPPORT_CHAT_MESSAGE } from '@/store/modules/support/mutation-types';
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard';

const {
    mapMutations: mapSupportMutations
} = createNamespacedHelpers('support');

export default {
    components: {
        Chat,
        CopyToClickBoard
    },
    props: {
        chat: {
            type: Array,
            required: true
        },
        chatLoading: {
            type: Boolean,
            required: true
        },
        ticketId: {
            type: String
        },
        orderId: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        ownerRoles: {
            type: Array,
            required: false,
            default: () => ['Admin', 'Writer']
        }
    },
    data() {
        return {
            typing: false,
            typingMessage: ''
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticketId
            })
        }
    },
    created() {
        this.socketConnect()
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', { ticketid: this.ticketId })
    },
    methods: {
        ...mapSupportMutations([
            SET_SUPPORT_CHAT_MESSAGE
        ]),
        typingListener(value) {
            this.typing = value
        },
        onSendChatMessage(payload) {
            this.$emit('sendChatMessage', payload)
        },
        closeChat(e) {
            if (e.target.closest('.popup')) return
            this.$emit('closeChat')
        },
        socketConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticketId })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author && data.author.id === this.getterMainInfo.id) return
                this[SET_SUPPORT_CHAT_MESSAGE]({ ...data, ticketid: this.ticketId })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Admin') return
                this.typingMessage = data.message
            })

            this.$socket.on('disconnect', () => {
                this.socketConnect()
            });
        }
    }
}
</script>

<style lang="scss">
    .chat_modal{
        max-width: 60%;
        width: 100%;
        position: fixed;
        z-index: 9;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 0 20px;
        @media (max-width: 599px) {
            padding: 0 8px;
            max-width: 100%;
        }
        .chat_items {
            max-height: calc(100% - 105px) !important;
            padding-left: 0;
            padding-right: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar { width: 0; }
        }
        &_typing-message{
            min-height: 20px;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 5px;
        }
    }
</style>
