export const MAILS_VIEWER_TABLE_HEADER_TOTAL = [
    {
        text: 'Total',
        value: 'total',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    },
    {
        text: ''
    }
]

export const MAILS_VIEWER_TABLE_HEADER = [
    {
        text: 'ID',
        value: 'id',
        width: '75px',
        align: 'center',
        sortable: true
    },
    {
        text: 'Domain',
        value: 'domain',
        width: '90px',
        sortable: true
    },
    {
        text: 'Title',
        value: 'title',
        width: '180px',
        sortable: true
    },
    {
        text: 'Description',
        value: 'description',
        sortable: true
    },
    {
        text: 'Subject',
        value: 'subject',
        sortable: true
    },
    {
        text: 'Delay',
        value: 'delay',
        width: '120px',
        sortable: true
    },
    {
        text: 'Priority',
        value: 'priority',
        width: '120px',
        sortable: true
    },
    {
        text: 'Date',
        value: 'created_at',
        sortable: true
    }
]
