<template>
    <div class="">
        <v-dialog
            v-model="showModal"
            :max-width="maxWidth"
            persistent
            @click:outside="onCloseModal"
        >
            <v-card>
                <v-card-title class="text-subtitle-1 py-5 px-5 modal--border d-flex justify-space-between align-center">
                    <span>
                        <span>{{ title }}</span>
                    </span>
                    <v-hover
                        v-slot="{ hover }"
                        style="cursor: pointer;"
                    >
                        <v-icon
                            :color="hover ? '#000' : 'gray'"
                            @click="onCloseModal"
                        >
                            mdi-close
                        </v-icon>
                    </v-hover>
                </v-card-title>

                <v-card-text :class="`pt-5 pb-0 px-5`">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <!-- order autocomplete -->
                            <v-col cols="12">
                                <div class="">
                                    <v-autocomplete
                                        ref="orderid"
                                        v-model="orderid"
                                        clearable
                                        color="accent"
                                        outlined
                                        :items="orders"
                                        :loading="orderLoading"
                                        item-value="orderid"
                                        :filter="(item) => item"
                                        :search-input.sync="orderSearch"
                                        hide-details
                                        label="Order ID"
                                        :rules="[rules.required]"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                            </div>
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column w-full">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                                <v-divider class="w-full mt-2" />
                                            </div>
                                        </template>
                                        <template v-slot:append-item>
                                            <div v-intersect="endOrderid" />
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <slot
                    name="actions"
                >
                    <v-card-actions
                        class="py-5 px-5"
                    >
                        <v-btn
                            color="accent"
                            class="py-4 px-3 mr-4"
                            height="max-content"
                            :loading="loading"
                            :disabled="!isActiveConfirm"
                            @click="onConfirmModal"
                        >
                            confirm
                        </v-btn>

                        <v-btn
                            class="py-4 px-3"
                            height="max-content"
                            @click="onCloseModal"
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </slot>
            </v-card>
        </v-dialog>

        <ModalAlert
            v-if="showConfirmModal"
            title="Confirm the action"
            description="Do you really want to close the window? Your changes will not be saved"
            @confirmModal="onCloseModalMainModal"
            @closeModal="onCloseModalSubModal"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js'
import ModalAlert from '@/components/shared/Modals/ModalAlert'

export default {
    components: {
        ModalAlert
    },
    props: {
        ticket: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: false,
            default: 'Bind order to ticket'
        },
        maxWidth: {
            type: Number,
            default: 600
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        isActiveConfirm: {
            type: Boolean,
            require: false,
            default: true
        },
        client_id: {
            type: Number,
            require: false
        }
    },
    data() {
        return {
            showConfirmModal: false,
            showModal: true,
            // order logic
            orderReadonly: false,
            orderid: '',
            orders: [],
            orderSearch: '',
            orderLoading: false,
            orderPage: 0,
            order_last_page: Infinity,
            orderSearchBy: 'orderid',
            // helpers
            dataPrependedOrder: false,
            valid: true,
            rules: {
                required: (value) => !!value || 'Required.'
            }
        }
    },
    computed: {},
    watch: {
        orderSearch: {
            handler: debounce(async function () {
                this.orderPage = 1
                await this.makeOrderSearch()
            }, 300)
        }
    },
    methods: {
        onCloseModal() {
            this.showConfirmModal = true
        },
        onCloseModalMainModal() {
            this.showConfirmModal = false
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onCloseModalSubModal() {
            this.showConfirmModal = false
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        orderid: this.orderid,
                        ticketid: this.ticket.ticketid
                    })
                } else {
                    eventBus.$emit('showSnackBar', 'Please complete form', 'error');
                }
            })
        },
        // Order select
        async getOrders(payload) {
            this.orderLoading = true
            try {
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.orderPage, mode: 'list', client_id: this.client_id
                }))
                this.orders = [...this.orders, ...data.data]
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async makeOrderSearch() {
            this.orders = []
            this.order_last_page = Infinity
            if (this.orderSearch && this.orderSearch.toLowerCase().includes('s-')) {
                this.orderSearchBy = 'orderid'
            } else {
                this.orderSearchBy = 'title'
            }
            await this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
        },
        endOrderid(entries, observer, isIntersecting) {
            if (isIntersecting && this.order_last_page >= this.orderPage) {
                this.orderPage += 1
                this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        }
    }
}
</script>

<style lang="scss">
    .search_by_client{
        max-width: 140px;
        fieldset{
            border-right: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
</style>
