<template>
    <div
        class="wrapper py-5 mails-viewer"
    >
        <v-container
            fluid
            class="mails-viewer_window white rounded d-flex align-start justify-space-between"
        >
            <v-form
                ref="form"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-model="domain"
                    name="domain"
                    label="Domain"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="title"
                    name="title"
                    label="Title"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="description"
                    name="description"
                    label="Description"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="subject"
                    name="subject"
                    label="Subject"
                    outlined
                    color="accent"
                    clearable
                />
                <div class="d-flex">
                    <v-btn
                        color="primary"
                        height="56px"
                        width="200px"
                        class=""
                        :disabled="!html"
                        :loading="loadingSubmit"
                        type="submit"
                    >
                        Submit
                    </v-btn>
                    <v-btn
                        v-if="hasId"
                        color="primary"
                        height="56px"
                        width="200px"
                        class="ml-4"
                        :loading="isLoading"
                        :disabled="!html"
                        @click="openModal()"
                    >
                        Preview
                    </v-btn>
                </div>
            </v-form>
            <div class="mails-viewer_editor">
                <v-form>
                    <v-textarea
                        v-model="html"
                        name="Editor"
                        filled
                        label="Editor"
                        auto-grow
                    />
                </v-form>
            </div>
        </v-container>

        <v-container
            fluid
            class="mails-viewer_window white rounded d-flex align-start justify-space-between"
        >
            <v-card class="mails-viewer_example">
                <div
                    v-html="html"
                />
            </v-card>
            <mail-preview
                v-if="showModal"
                :id="id"
                :title="title"
                :html="html"
                @closeModal="onCloseModal"
            />
        </v-container>
        <table-loader
            v-if="isLoading"
        />
    </div>
</template>

<script>
// import Api from '@/helpers/api/index.js'
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_GET_MAILING_TEMPLATE,
    ACTION_CREATE_OR_UPDATE_TEMPLATE
} from '@/store/modules/mailing/action-types';

import { eventBus } from '@/helpers/event-bus/'

import MailPreview from '@/components/shared/Modals/MailPreview'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'

const {
    mapActions: mapFeedbacksActions
} = createNamespacedHelpers('mailing');

export default {
    components: {
        MailPreview,
        TableLoader
    },
    data() {
        return {
            html: null,
            id: null,
            domain: null,
            title: null,
            description: null,
            subject: null,
            isLoading: false,
            hasId: false,
            showModal: false,
            loadingSubmit: false
            // templateHtml: null
        };
    },
    computed: {
        formData() {
            const {
                html, domain, title, description, subject
            } = this
            return {
                html, domain, title, description, subject
            }
        }
    },
    created() {
        if (this.$route.params.id) {
            this.hasId = true
            this.getMail()
        }
    },
    methods: {
        ...mapFeedbacksActions({
            ACTION_CREATE_OR_UPDATE_TEMPLATE,
            ACTION_GET_MAILING_TEMPLATE
        }),
        async getMail() {
            this.isLoading = true
            try {
                const data = await this[ACTION_GET_MAILING_TEMPLATE]({ id: this.$route.params.id })
                this.html = data.html
                this.id = data.id
                this.domain = data.domain
                this.title = data.title
                this.description = data.description
                this.subject = data.subject
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async openModal() {
            // await this.getPreview()
            this.showModal = true
        },
        async submit() {
            try {
                this.loadingSubmit = true
                if (!this.hasId) {
                    await this[ACTION_CREATE_OR_UPDATE_TEMPLATE](this.formData)
                } else {
                    await this[ACTION_CREATE_OR_UPDATE_TEMPLATE]({ ...this.formData, id: this.id })
                }
                eventBus.$emit('showSnackBar', 'Template has been created', 'success')
                this.$router.push('/settings/mailing-templates')
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error')
            } finally {
                this.loadingSubmit = false
            }
        },
        // async getPreview() {
        //     try {
        //         const { data } = await Api.get('/api/mail/remarketing/render', { id: this.id })
        //         this.templateHtml = data
        //     } catch (e) {
        //         eventBus.$emit('showSnackBar', e, 'error')
        //     }
        // },
        onCloseModal() {
            this.showModal = false
        }
    }
}
</script>

<!-- <style lang="scss">
    .mails-viewer {
        &_input {
            width: 33%;
        }
    }
</style> -->
