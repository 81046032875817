<template>
    <viewer
        :title="'Project types'"
        @closeSidePanel="closeSiteSettings"
    >
        <template #body>
            <v-row>
                <v-col
                    cols="6"
                    class="pt-8"
                >
                    <h3>Site types</h3>
                    <draggable
                        class="list-group"
                        :list="siteTypes"
                        group="types"
                    >
                        <div
                            v-for="element in siteTypes"
                            :key="element.short_name"
                            class="list-group-item"
                        >
                            <span>{{ element.long_name }}</span>
                            <v-text-field
                                v-model="element.rate"
                                class="mt-4 mb-0"
                                label="Rate"
                                outlined
                                dense
                            />
                        </div>
                    </draggable>
                </v-col>
                <v-col
                    cols="6"
                    class="pt-8"
                >
                    <h3>All types</h3>
                    <draggable
                        class="list-group"
                        :list="allTypes"
                        group="types"
                    >
                        <div
                            v-for="element in allTypes"
                            :key="element.short_name"
                            class="list-group-item"
                        >
                            <span>{{ element.long_name }}</span>
                            <v-text-field
                                v-model="element.rate"
                                class="mt-4 mb-0"
                                label="Rate"
                                outlined
                                dense
                                readonly
                                disabled
                            />
                        </div>
                    </draggable>
                </v-col>
            </v-row>
            <div class="project_types_button">
                <v-btn
                    color="accent"
                    @click="save()"
                >
                    Update types
                </v-btn>
            </div>
        </template>
    </viewer>
</template>

<script>
import draggable from 'vuedraggable'
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import Viewer from '@/components/SidePanels/Viewer'

export default {
    name: 'SiteSettings',
    components: {
        Viewer,
        draggable
    },
    props: {
        types: {
            reqired: true,
            type: Array
        }
    },
    data() {
        return {
            isLoading: true,
            allTypes: null,
            siteTypes: [...this.types]
        }
    },
    async created() {
        await this.getProjectTypes()
    },
    methods: {
        closeSiteSettings() {
            this.$emit('closeSiteSettings')
        },
        async getProjectTypes() {
            try {
                this.isLoading = true
                const { data } = await Api.get('/api/settings/get-settings')
                const values = data.find((item) => item.key === 'project_types').value
                this.allTypes = values.filter((item) => {
                    const find = this.types.findIndex((type) => type.short_name === item.short_name)
                    if (find === -1) return true
                    return false
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        save() {
            this.$emit('saveTypes', [...this.siteTypes])
        }
    }
}
</script>

<style lang="scss">
    .project_types_button{
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        display: flex;
        justify-content: center;
        background: #fff;
        box-shadow: 0px -11px 29px -20px rgba(0,0,0,0.75);
    }
    .list-group{
        background: rgba(0,0,0,0.1);
        height: 100%;
        &-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-bottom: -1px;
            background-color: #fff;
            padding: 0 20px;
            border: 1px solid rgba(0,0,0,.125);
            .v-input{
                max-width: 90px;
            }
            & > span {
                font-weight: bold;
            }
        }
    }
</style>
