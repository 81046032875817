<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-select
                    v-model="preset"
                    :items="getterPopulatePresets"
                    label="Members"
                    class="my-3"
                    outlined
                    :rules="[rules.required]"
                    required
                    hide-details
                />
            </v-form>
        </template>
    </modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import Modal from '@/components/shared/Modals/Modal'
import {
    ACTION_GET_MEMBERS_GROUP
} from '@/store/modules/mailing/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('mailing');
export default {
    name: 'ModalPopulateMailing',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            valid: false,
            showModal: true,
            preset: '',
            rules: {
                required: (value) => !!value || 'Required.'
            }
        }
    },
    computed: {
        ...mapGetters('mailing', [
            'getterPopulatePresets'
        ])
    },
    async created() {
        await this[ACTION_GET_MEMBERS_GROUP]()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_MEMBERS_GROUP
        }),
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        preset: this.preset
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>
