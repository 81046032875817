<template>
    <Viewer
        :title="'Mailing details'"
        @closeSidePanel="close"
    >
        <template #body>
            <v-data-table
                :headers="tableHeader"
                :items="members"
                item-key="address"
                :loading="false"
                hide-default-footer
                calculate-widths
                class="mt-10"
                must-sort
                :sort-by="'name'"
                :sort-desc="false"
            >
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="loading && members.length === 0"
                        :column-count="tableHeaderLength"
                        :per-page="10"
                    />
                    <tbody
                        class="bids_scroll"
                    >
                        <tr
                            v-for="item in items"
                            :key="item.address"
                            class="hovered-row"
                        >
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.address }}
                            </td>
                            <td>
                                <v-icon
                                    style="cursor: pointer"
                                    @click="removeMember(item.address)"
                                >
                                    mdi-close
                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                    <table-loader
                        v-if="loading"
                    />
                </template>
            </v-data-table>
            <div v-if="members.length >= per_page">
                <v-btn
                    class="mr-4"
                    color="primary"
                    :disabled="!pagination.hasOwnProperty('previous')"
                    @click="fetchMailing(pagination.previous)"
                >
                    Prev
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!pagination.hasOwnProperty('next')"
                    @click="fetchMailing(pagination.next)"
                >
                    Next
                </v-btn>
            </div>
            <div
                v-if="!members.length && !loading"
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No data found
                </p>
            </div>
            <ModalAlert
                v-if="showModalDelete"
                :title="modal.title"
                :description="modal.description"
                :loading="loadingInModal"
                @closeModal="onCloseModal"
                @confirmModal="deleteMember"
            />
        </template>
    </Viewer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import Viewer from '@/components/SidePanels/Viewer'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import {
    MAILING_MEMBERS_TABLE_HEADER
} from '@/constants/MailingList'
import {
    ACTION_FETCH_MAILING,
    ACTION_REMOVE_MEMBER
} from '@/store/modules/mailing/action-types';
import TableLoader from '@/components/shared/Tables/TableLoader.vue';

const {
    mapActions: mapMailingActions
} = createNamespacedHelpers('mailing');

export default {
    name: 'MailingViewer',
    components: {
        TableLoader,
        Viewer,
        TableSkeleton,
        ModalAlert
    },
    props: {
        address: {
            type: String,
            require: true
        }
    },
    data() {
        return {
            members: [],
            loading: true,
            modal: null,
            showModalDelete: false,
            selectedMember: null,
            loadingInModal: false,
            pagination: null,
            per_page: 10
        }
    },
    computed: {
        tableHeader() {
            return MAILING_MEMBERS_TABLE_HEADER
        },
        tableHeaderLength() {
            return MAILING_MEMBERS_TABLE_HEADER.length
        }
    },
    async created() {
        await this.fetchMailing()
    },
    methods: {
        ...mapMailingActions({
            ACTION_FETCH_MAILING,
            ACTION_REMOVE_MEMBER
        }),
        close() {
            this.$emit('close')
        },
        async fetchMailing(btn) {
            try {
                this.loading = true
                if (this.members.length) {
                    const { members, paging } = await this[ACTION_FETCH_MAILING]({
                        name: this.address,
                        page: btn.page,
                        address: btn.address,
                        per_page: this.per_page
                    })
                    this.members = members
                    this.pagination = paging
                } else {
                    const { members, paging } = await this[ACTION_FETCH_MAILING]({
                        name: this.address,
                        per_page: this.per_page
                    })
                    this.members = members
                    this.pagination = paging
                }
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loading = false
            }
        },
        async deleteMember() {
            try {
                this.loadingInModal = true
                await this[ACTION_REMOVE_MEMBER]({
                    mailing_list: this.address,
                    address: this.selectedMember
                })
                const index = this.members.findIndex(({ address }) => address === this.selectedMember)
                this.members.splice(index, 1)
                eventBus.$emit('showSnackBar', 'Member was deleted', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
                this.onCloseModal()
            }
        },
        removeMember(memberEmail) {
            this.selectedMember = memberEmail
            this.modal = {
                title: 'Delete Member',
                description: `Do you want to delete ${memberEmail} from the members?`
            }
            this.showModalDelete = true
        },
        onCloseModal() {
            this.selectedMember = null
            this.modal = null
            this.showModalDelete = false
        }
    }
}
</script>
