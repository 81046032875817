<template>
    <div class="">
        <v-data-table
            :headers="tableHeader"
            :items="tableItems"
            item-key="ticketid"
            :server-items-length="total"
            :loading="loading"
            hide-default-footer
            calculate-widths
            class="mt-4"
            must-sort
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:options="sortTable"
        >
            <template v-slot:body="{ items }">
                <table-skeleton
                    v-if="loading"
                    :per-page="per_page"
                    :column-count="tableHeaderLength"
                />
                <tbody
                    v-else
                >
                    <tr
                        v-for="(item, index) in items"
                        :key="item.id"
                    >
                        <!-- Index -->
                        <td class="td_index">
                            {{ (per_page * (page - 1)) + index + 1 }}
                        </td>
                        <!-- PRIORITY -->
                        <td class="td_priority">
                            <div class="mb-0 mt-2 ml-2 d-flex align-center">
                                <img
                                    v-if="item.status_priority"
                                    :src="require(`@images/svg/${getIcon(item.status_priority)}`)"
                                    :alt="item.status_priority"
                                >
                            </div>
                        </td>
                        <!-- CREATED -->
                        <td class="td_created max-w-130 break-word">
                            <div
                                v-if="item.admin"
                                class="d-flex mb-4"
                            >
                                <avatar-rolles
                                    access-status="admin"
                                    :firstname="item.admin.admin_firstname"
                                    :lastname="item.admin.admin_lastname"
                                    :avatar-url="item.admin.avatar_url"
                                    :avatar-fallback="item.admin.avatar_fallback"
                                    avatar-size="45"
                                />
                                <div class="ml-4">
                                    <p class="mb-0">
                                        {{ item.admin.admin_firstname }} {{ item.admin.admin_lastname }}
                                    </p>
                                    <p class="mb-0">
                                        Admin ID: {{ item.admin.id }}
                                    </p>
                                </div>
                            </div>
                            <p>
                                {{ $date.humanizeDate(item.created_at) }}
                            </p>
                        </td>
                        <!-- SUBJECT -->
                        <td
                            class="td_subject"
                        >
                            <p class="text-subtitle-2 black--text text-decoration-none mb-1">
                                {{ item.title }}
                            </p>
                            <p>
                                <b>Reference ID:</b>
                                <span
                                    class="ml-2"
                                    v-html="getLinkByReference(item.referenceid)"
                                />
                            </p>
                            <v-divider />
                            <div
                                class="table_message mt-4"
                                style="cursor: pointer"
                                @click="showViewer(item.threadid)"
                            >
                                <div
                                    v-if="item.last_message"
                                    class="d-flex message"
                                >
                                    <div
                                        class="d-flex mr-2"
                                    >
                                        <avatar-rolles
                                            :access-status="'admin'"
                                            :firstname="item.last_message.admin.admin_firstname"
                                            :lastname="item.last_message.admin.admin_lastname"
                                            :avatar-url="item.last_message.admin.avatar_url"
                                            :avatar-fallback="item.last_message.admin.avatar_fallback"
                                            avatar-size="45"
                                        />
                                    </div>
                                    <div class="w-full">
                                        <p class="gray--text mb-0 w-full d-flex">
                                            <span class="text-subtitle-2 mr-auto">
                                                {{ item.last_message.admin.admin_firstname }} {{ item.last_message.admin.admin_lastname }}
                                            </span>

                                            <span class="mr-5 grey--text text-subtitle-2 text-body-1">{{ $date.dateFromNow(item.last_message.created_at) }}</span>

                                            <span class="grey--text text-body-1">{{ $date.humanizeDate(item.last_message.created_at) }}</span>
                                        </p>
                                        <div class="message__data w-full py-5 px-5">
                                            <div class="d-flex">
                                                <p class="break-word mb-0">
                                                    {{ item.last_message.details }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="d-flex"
                                >
                                    <div class="message__data w-full py-5 px-5">
                                        <div class="d-flex">
                                            <p class="break-word mb-0">
                                                There are no messages here yet. Click here to send
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <!-- SUBJECT -->
                        <td
                            class="td_subject"
                        >
                            <status-colored
                                :type="item.status"
                                class="mb-2"
                            />
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        <div
            v-if="!tableItems.length"
            class="d-flex flex-column align-center w-full mt-5 mb-5"
        >
            <div class="">
                <img
                    src="@images/svg/no-data.svg"
                    alt=""
                >
            </div>
            <p class="text-h6">
                No tickets found
            </p>
        </div>

        <pagination-with-perpage
            :list-lenght="tableItems.length"
            :last-page="lastPage"
            :search-options="{ page, per_page }"
            :total-visible="7"
            @updatePage="paginationPageEvent"
            @updatePerpage="onUpdatePerpage"
        />
    </div>
</template>

<script>
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import StatusColored from '@/components/shared/Helpers/StatusColored'

import {
    FORUM_TABLE_HEAD
} from '@/constants/Support/forum'

export default {
    components: {
        PaginationWithPerpage,
        TableSkeleton,
        AvatarRolles,
        StatusColored
    },
    props: {
        tableItems: {
            type: Array,
            reqiured: true
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        lastPage: {
            type: Number,
            default: 0
        },
        per_page: {
            type: Number,
            default: 10
        },
        currentItem: {
            type: Object
        }
    },
    data() {
        return {
            selected: [],
            hoveredItem: null,
            page: null,
            flag: true
        }
    },
    computed: {
        tableHeader() {
            return FORUM_TABLE_HEAD
        },
        tableHeaderLength() {
            return FORUM_TABLE_HEAD.length + 1
        },
        sortBy() {
            return this.$route.query.sort_by || 'ts_issued'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        },
        isWritersTable() {
            return this.$route.name.includes('writer')
        },
        hideAsResolvedItem() {
            return {
                actionName: 'resolve_ticket',
                modal: {
                    name: 'ModalAlert',
                    title: 'Hide as Resolved',
                    description: 'Do you really go to close the ticket?'
                }
            }
        }
    },
    watch: {
        selected() {
            this.$emit('selected', this.selected)
        },
        tableItems: {
            handler(newVal) {
                this.selected = this.selected.map((select) => {
                    const find = newVal.find((item) => item.ticketid === select?.ticketid)
                    return find
                })
            },
            deep: true
        },
        currentPage() {
            this.page = this.currentPage
        }
    },
    created() {
        this.page = this.currentPage
    },
    methods: {
        paginationPageEvent(e) {
            this.$emit('paginate', e)
        },
        sortTable(e) {
            const {
                sortBy,
                sortDesc
            } = e

            this.$emit('sortTable', this.flag, {
                sort_by: sortBy[0],
                sort_order: sortDesc[0] === true ? 'ASC' : 'DESC'
            })
            this.flag = false
        },
        onActionRow(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        hideAsResolved(item) {
            this.$emit('hideAsResolved', item)
        },
        onUpdatePerpage(payload) {
            this.$emit('updatePerpage', payload)
        },
        getIcon(priority) {
            switch (priority) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return false
            }
        },
        onMenuActivated(item, modal) {
            this.$emit('actionRow', item, modal)
        },
        showViewer(threadid) {
            this.$emit('showViewer', threadid)
        },
        getLinkByReference(id) {
            const prefix = `${id[0]}${id[1]}`
            if (id && prefix && (prefix === 'TC' || prefix === 'TW' || prefix === 'S-')) {
                if (prefix === 'TC') {
                    return `<a href='/support/client/${id}'>${id}</a>`
                }
                if (prefix === 'TW') {
                    return `<a href='/support/writer/${id}'>${id}</a>`
                }
                if (prefix === 'S-') {
                    return `<a href='/orders/${id}'>${id}</a>`
                }
            }
            return id
        }
    }

}
</script>

<style lang="scss">
    .max-w-130{
        max-width: 130px;
    }
    .message{
        &__data{
            width: calc(100% - 75px);
            background: #f2f6f9 ;
            border-radius: 12px;
            &--admin{
                background: #ddf1ff;
            }
            &--accent{
                background: #d7ebf7;
            }
        }
    }
</style>
