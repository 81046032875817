<template>
    <div class="wrapper py-5 writers-page">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="onCreateForumClick"
                    >
                        Create forum
                    </v-btn>
                </template>
            </the-main-filter>

            <forum-table
                :table-items="forums"
                :total="total"
                :current-page="searchOptions.page"
                :last-page="last_page"
                :loading="isLoading"
                :per_page="searchOptions.per_page"
                @paginate="onPaginate"
                @sortTable="onSortTable"
                @updatePerpage="onFilterTable"
                @showViewer="onShowViewer"
            />

            <forum-viewer
                v-if="showViewer"
                :threadid="viewerId"
                @closeChat="onCloseChat"
                @sendChatMessage="onSendChatMessage"
            />

            <create-forum
                v-if="showCreator"
                @createForum="onCreateForum"
                @closeModal="onCloseForumCreator"
            />
        </v-container>
    </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { forumService } from '@/services/ForumService'
import { eventBus } from '@/helpers/event-bus/'
import {
    FORUM_LIST_SEARCH
} from '@/constants/Support/forum_filters'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import ForumTable from '@/components/Support/ForumTable'
import ForumViewer from '@/components/SidePanels/ForumViewer'
import CreateForum from '@/components/shared/Modals/CreateForum'

export default {
    components: {
        TheMainFilter,
        ForumTable,
        ForumViewer,
        CreateForum
    },
    data() {
        return {
            isLoading: true,
            forums: [],
            total: Infinity,
            last_page: Infinity,
            searchOptions: {
                page: 1,
                per_page: 10,
                status_priority: '',
                sort_by: 'ts_issued',
                sort_order: 'DESC',
                search_for: '',
                search_by: 'threadid',
                from: '',
                to: ''
            },
            showViewer: false,
            viewerId: null,
            showCreator: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        search_fields() {
            return FORUM_LIST_SEARCH
        }
    },
    methods: {
        async getForumList() {
            try {
                this.isLoading = true
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const response = await forumService.getList({ ...this.searchOptions })
                this.forums = response.data
                this.total = response.total
                this.last_page = response.last_page
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        onFilterTable(payload) {
            this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            this.getForumList()
        },
        async onPaginate(page) {
            this.searchOptions.page = page
            await this.getForumList()
        },
        async onSortTable(flag, option) {
            this.searchOptions = {
                ...this.searchOptions,
                ...option,
                page: 1
            }
            if (!flag) {
                await this.getForumList()
            }
        },
        onShowViewer(id) {
            this.showViewer = true
            this.viewerId = id
        },
        onCloseChat() {
            this.showViewer = false
            this.viewerId = null
        },
        async onSendChatMessage(payload) {
            try {
                const data = await forumService.sendMessage({ ...payload, threadid: this.viewerId })
                const findIndex = this.forums.findIndex((i) => i.threadid === this.viewerId)
                this.forums.splice(findIndex, 1, {
                    ...this.forums[findIndex],
                    last_message: {
                        ...data,
                        admin: {
                            ...this.getterMainInfo,
                            avatar: this.getterMainInfo.avatar_url,
                            admin_firstname: this.getterMainInfo.firstname,
                            admin_lastname: this.getterMainInfo.lastname
                        }
                    }
                })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async onCreateForum(payload) {
            try {
                await forumService.createForum({ ...payload })
                await this.getForumList()

                this.showCreator = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCreateForumClick() {
            this.showCreator = true
        },
        onCloseForumCreator() {
            this.showCreator = false
        }
    }
}
</script>

<style>

</style>
