<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <template
                    v-for="(member, index) in members"
                >
                    <div
                        :key="index"
                        class="d-flex"
                    >
                        <v-text-field
                            v-model="member.name"
                            outlined
                            color="accent"
                            class="my-3 mr-2"
                            label="Name"
                            hide-details
                            :rules="[rules.required]"
                            required
                        />
                        <v-text-field
                            v-model="member.address"
                            outlined
                            color="accent"
                            class="my-3"
                            label="Email address"
                            hide-details
                            :rules="[rules.required]"
                            required
                        />
                        <v-btn
                            color="primary"
                            height="56px"
                            :disabled="members.length < 2"
                            class="mt-3 ml-4"
                            @click="removeMember(index)"
                        >
                            <v-icon
                                right
                                dark
                                class="mr-2"
                            >
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </div>
                </template>
            </v-form>
            <v-btn
                color="primary"
                height="56px"
                class="mt-4"
                @click="addMember"
            >
                <v-icon
                    right
                    dark
                    class="mr-2"
                >
                    mdi-plus
                </v-icon>
                Add another member
            </v-btn>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    name: 'ModalAddMembers',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            showModal: true,
            preset: '',
            members: [
                {
                    address: '',
                    name: '',
                    vars: {}
                }
            ],
            rules: {
                required: (value) => !!value || 'Required.'
            }
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        members: this.members
                    })
                }
            })
        },
        addMember() {
            this.members.push({
                address: '',
                name: '',
                vars: {}
            })
        },
        removeMember(index) {
            this.members.splice(index, 1)
        }
    }
}
</script>

<style>

</style>
