var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4",staticStyle:{"min-width":"300px"}},[_c('div',{staticClass:"d-flex flex-wrap align-center justify-space-between mb-2"},[_c('div',{staticClass:"d-flex flex-wrap w-full align-center mb-2"},[(_vm.data.types.type)?_c('status-colored',{staticClass:"mb-2 mr-2",attrs:{"type":_vm.data.types.type}}):_vm._e(),(_vm.data.types.category)?_c('status-colored',{staticClass:"mb-2 mr-2",attrs:{"type":_vm.data.types.category}}):_vm._e(),(_vm.data.types.resolution)?_c('status-colored',{staticClass:"mb-2 mr-2",attrs:{"type":_vm.data.types.resolution}}):_vm._e()],1),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('router-link',{staticClass:"text-subtitle-2 black--text text-decoration-none d-flex align-center transition-fast-in-fast-out",class:hover ? 'info--text' : 'black--text',attrs:{"to":{
                    name: 'support-details',
                    params: {
                        id: _vm.data.ticketid,
                        type: _vm.typeTicket
                    }
                }}},[_vm._v(" "+_vm._s(_vm.data.subject || 'EMPTY')+" "),(_vm.data.ticketid)?_c('v-tooltip',{attrs:{"top":"","eager":"","max-width":"480px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"messages_counter"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.messagesCount)+" "),(_vm.unreadMessagesCount > 0)?_c('span',{staticClass:"messages_counter--unread"},[_vm._v(" "+_vm._s(_vm.unreadMessagesCount)+" ")]):_vm._e()])]}}],null,true)},[_c('div',{},[_c('p',{staticClass:"mb-1"},[_vm._v(" Messages: "+_vm._s(_vm.messagesCount)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" Unread messages: "+_vm._s(_vm.unreadMessagesCount)+" ")])])]):_vm._e(),(_vm.data.chk_callback)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"success"}},[_vm._v(" mdi-phone ")]):_vm._e()],1)]}}])}),_c('div',{staticClass:"d-flex flex-column"},[_c('v-tooltip',{attrs:{"top":"","open-on-click":"","open-on-hover":false,"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._b({staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":on.click,"blur":on.blur}},'v-icon',attrs,false),[_vm._v(" mdi-barcode ")])]}}])},[_c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('a',{staticClass:"font-weight-medium white--text text-decoration-none ml-auto",on:{"click":function($event){return _vm.goToDetails('ticket')}}},[_vm._v(" "+_vm._s(_vm.data.ticketid)+" "),_c('copy-to-click-board',{staticClass:"d-inline-flex",attrs:{"copy-string":_vm.data.ticketid,"icon-color":"white","success-mesage":"The ticket ID is copied.","icon":""}})],1)]),_c('div',{staticClass:"d-flex align-center"},[(_vm.data.orderid)?_c('a',{staticClass:"font-weight-medium white--text text-decoration-none",on:{"click":function($event){return _vm.goToDetails('order')}}},[_vm._v(" "+_vm._s(_vm.data.orderid)+" "),_c('copy-to-click-board',{staticClass:"d-inline-flex",attrs:{"copy-string":_vm.data.orderid,"success-mesage":" The order ID is copied.","icon":""}})],1):_vm._e()])])])],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('div',{on:{"click":function($event){return _vm.openChatModal({modal: _vm.toolbars[3].modal, actionName: _vm.toolbars[3].name})}}},[_c('support-last-reply-by',{attrs:{"last_message":_vm.data.last_message}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }