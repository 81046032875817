<template>
    <div class="wrapper py-5 mails-viewer">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-btn
                        color="success"
                        class="mr-3"
                        @click.stop="goToCreate()"
                    >
                        <v-icon
                            right
                            dark
                            class="mr-2"
                        >
                            mdi-plus
                        </v-icon>
                        Create
                    </v-btn>
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mailing-list'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing List
                        </router-link>
                    </v-hover>
                    <!-- <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mails'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mail Remarketing
                        </router-link>
                    </v-hover> -->
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mails-log'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing Log
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>
            <v-data-table
                must-sort
                :headers="tableHeader"
                :items="getterMembersTemplatesList"
                item-key="id"
                :loading="false"
                hide-default-footer
                calculate-widths
                class="mt-4"
            >
                <template v-slot:body="{ items }">
                    <!-- <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    /> -->
                    <tbody>
                        <template>
                            <tr
                                v-for="item in items"
                                :key="item.id"
                                class="cursor--pointer hovered-row"
                                @click="goToEdit(item.id)"
                            >
                                <!-- Index -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.id }}
                                    </p>
                                </td>
                                <!-- title -->
                                <td>
                                    <p class="mb-0">
                                        {{ item.title }}
                                    </p>
                                </td>
                                <!-- subject -->
                                <td>
                                    <p class="mb-0">
                                        {{ item.subject }}
                                    </p>
                                </td>
                                <!-- description -->
                                <td>
                                    <p class="mb-0">
                                        {{ item.description }}
                                    </p>
                                </td>
                                <!-- created_at -->
                                <td>
                                    <div class="d-flex align-center">
                                        <p class="mb-0">
                                            {{ item.created_at && $date.fromWithText(item.created_at) }}
                                        </p>
                                        <information-tooltip>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Created:
                                                </p>
                                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                    {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                                </p>
                                            </div>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Updated:
                                                </p>
                                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                    {{ item.updated_at ? $date.fullDate(item.updated_at) : '—' }}
                                                </p>
                                            </div>
                                        </information-tooltip>
                                    </div>
                                </td>
                                <!-- domain -->
                                <td>
                                    <domain-favicon
                                        :domain="item.domain"
                                    />
                                </td>
                                <td>
                                    <table-rows-menu
                                        :toolbars="toolbars"
                                        :item="item"
                                        @menuActivated="onMenuActivated"
                                    />
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <table-loader
                        v-if="isLoading"
                    />
                    <table-skeleton
                        v-if="isLoading && listLength === 0"
                        :per-page="10"
                        :column-count="tableHeaderLength"
                    />
                </template>
            </v-data-table>

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :loading="loadingInModal"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
            <!-- <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterFeedbacksList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            /> -->
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
    ACTION_GET_MAILING_TEMPLATES_LIST,
    ACTION_DELETE_TEMPLATE
} from '@/store/modules/mailing/action-types';

import { eventBus } from '@/helpers/event-bus/'
import {
    MAILING_TEMPLATES_TABLE_HEADER,
    MAILING_TEMPLATES_TOOLBARS
} from '@/constants/MailingTemplates'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import InformationTooltip from '@/components/shared/InformationTooltip'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'

const {
    mapActions: mapMailingActions
} = createNamespacedHelpers('mailing');

export default {
    components: {
        InformationTooltip,
        TheMainFilter,
        TableLoader,
        TableSkeleton,
        TableRowsMenu,
        ModalAlert,
        DomainFavicon
    },
    data() {
        return {
            list: [],
            modal: {},
            currentItem: null,
            html: null,
            isLoading: false,
            loadingInModal: false,
            search_fields: {
                datepicker: {
                    cols: 12,
                    value: 'datepicker'
                },
                domain: {
                    value: 'domain',
                    label: 'Domain',
                    list: [
                        {
                            text: 'All',
                            value: 'all'
                        },
                        {
                            text: 'customessaymeister.com',
                            value: 'customessaymeister.com'
                        },
                        {
                            text: 'dreamessays.com',
                            value: 'dreamessays.com'
                        },
                        {
                            text: 'solidpapers.com',
                            value: 'solidpapers.com'
                        },
                        {
                            text: 'genuinewriting.com',
                            value: 'genuinewriting.com'
                        },
                        {
                            text: 'professays.com',
                            value: 'professays.com'
                        },
                        {
                            text: 'paper.coach',
                            value: 'paper.coach'
                        }
                    ]
                },
                search_by: {
                    value: 'search_by',
                    label: 'Search by field',
                    list: [
                        {
                            text: 'Domain',
                            value: 'domain'
                        },
                        {
                            text: 'Priority',
                            value: 'priority'
                        },
                        {
                            text: 'Delay',
                            value: 'delay'
                        },
                        {
                            text: 'Description',
                            value: 'description'
                        },
                        {
                            text: 'Title',
                            value: 'title'
                        },
                        {
                            text: 'Subject',
                            value: 'subject'
                        }
                    ]
                }
                // sort_by: {
                //     value: 'sort_by',
                //     label: 'Sort by fields',
                //     list: [
                //         {
                //             text: 'Created at',
                //             value: 'created_at'
                //         },
                //         {
                //             text: 'Updated at',
                //             value: 'updated_at'
                //         },
                //         {
                //             text: 'ID',
                //             value: 'id'
                //         },
                //         {
                //             text: 'Domain',
                //             value: 'domain'
                //         },
                //         {
                //             text: 'Priority',
                //             value: 'priority'
                //         },
                //         {
                //             text: 'Delay',
                //             value: 'delay'
                //         },
                //         {
                //             text: 'Description',
                //             value: 'description'
                //         },
                //         {
                //             text: 'Title',
                //             value: 'title'
                //         },
                //         {
                //             text: 'Subject',
                //             value: 'subject'
                //         }
                //     ]
                // }
            },
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            }
        };
    },
    computed: {
        ...mapGetters('mailing', [
            'getterMembersTemplatesList'
        ]),
        toolbars() {
            return MAILING_TEMPLATES_TOOLBARS
        },
        tableHeader() {
            return MAILING_TEMPLATES_TABLE_HEADER
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        tableHeaderLength() {
            return MAILING_TEMPLATES_TABLE_HEADER.length + 1
        },
        listLength() {
            return this.list.length
        }
    },
    created() {
        // this.$nextTick(() => {
        //     window.scroll(0, this.savedScroll.y);
        // })
        // this.searchOptions = {
        //     ...this.searchOptions,
        //     ...this.queryObject
        // }
        this.getMailingList()
    },
    methods: {
        ...mapMailingActions({
            ACTION_GET_MAILING_TEMPLATES_LIST,
            ACTION_DELETE_TEMPLATE
        }),
        async getMailingList() {
            // if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
            //     this.$router.replace({ query: { ...this.searchOptions } })
            // }
            this.isLoading = true
            try {
                await this[ACTION_GET_MAILING_TEMPLATES_LIST]({ ...this.searchOptions })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        goToEdit(id) {
            this.$router.push(`/settings/mailing-template-edit/${id}`)
        },
        goToCreate() {
            this.$router.push('/settings/mailing-template-create')
        },
        async deleteTemplate() {
            await this[ACTION_DELETE_TEMPLATE]({ id: this.currentItem.id })
        },
        onFilterTable(payload) {
            const newObj = Object.entries(payload).reduce((acc, curr) => {
                acc[curr[0]] = this.searchOptions[curr[0]] || ''
                return acc
            }, {})
            if (JSON.stringify(payload) !== JSON.stringify(newObj)) {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
                this.getMailingList()
            }
        },
        // MODALS ACTION
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        },
        async onConfirmModal() {
            try {
                this.loadingInModal = true
                switch (this.actionName) {
                case 'delete':
                    await this.deleteTemplate()
                    break;
                default:
                    break;
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingInModal = false
            }
        }
    }
}
</script>

<style lang="scss">
    .mails-viewer {
        .cursor--pointer {
            cursor: pointer;
        }
        &_window {
            width: 100%;
        }
        &_example {
            display: flex;
            justify-content: center;
            width: 100%;
            max-height: 600px;
            overflow-y: scroll;
        }
        &_editor {
            width: 100%;
            max-width: 60%;
            max-height: 580px;
            min-height: 580px;
            margin-left: 20px;
            overflow-y: scroll;
            flex-grow: 1;
            .v-text-field__details {
                display:  none;
            }
            .v-input__slot {
                margin-bottom: 0;
            }
        }
    }
</style>
