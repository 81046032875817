export const FORUM_LIST_SEARCH = {
    status_priority: {
        label: 'Priority',
        value: 'status_priority',
        cols: 12,
        list: [
            {
                text: 'All',
                value: 'all'
            },
            {
                text: 'Low',
                value: 'LOW'
            },
            {
                text: 'Normal',
                value: 'NORMAL'
            },
            {
                text: 'High',
                value: 'HIGH'
            }
        ]
    },
    search_by: {
        value: 'search_by',
        cols: 12,
        list: [
            {
                text: 'Title',
                value: 'title'
            },
            {
                text: 'Thread ID',
                value: 'threadid'
            }
        ]
    },
    datepicker: {
        cols: 12,
        value: 'datepicker'
    }
}
