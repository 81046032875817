export const MAILING_LIST_TABLE_HEADER = [
    {
        text: 'Name',
        value: 'name',
        sortable: true
    },
    {
        text: 'Created at',
        value: 'created_at',
        sortable: true
    },
    {
        text: 'Address',
        value: 'address',
        width: '180px',
        sortable: false
    },
    {
        text: 'Description',
        value: 'description',
        sortable: false
    },
    {
        text: 'Members count',
        value: 'members_count',
        sortable: true
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const MAILING_LIST_TOOLBARS = [
    {
        name: 'populate',
        text: 'Populate',
        modal: {
            name: 'ModalPopulateMailing',
            title: 'Populate',
            success_message: 'Members added successfully'
        },
        icon: 'mdi-account-group'
    },
    {
        name: 'add_members',
        text: 'Add members',
        modal: {
            name: 'ModalAddMembers',
            title: 'Add members',
            success_message: 'Members added successfully'
        },
        icon: 'mdi-account-multiple'
    },
    {
        name: 'send',
        text: 'Send',
        modal: {
            name: 'ModalSendMailing',
            title: 'Send mailing',
            success_message: 'Mailing list was sended'
        },
        icon: 'mdi-email-arrow-right'
    },
    {
        name: 'delete',
        text: 'Delete',
        modal: {
            name: 'ModalAlert',
            title: 'Delete mailing',
            description: 'Do you want to delete mailing list?',
            success_message: 'Mailing list was deleted'
        },
        icon: 'mdi-close'
    }
]

export const MAILING_MEMBERS_TABLE_HEADER = [
    {
        text: 'Name',
        value: 'name',
        sortable: true
    },
    {
        text: 'Address',
        value: 'address',
        sortable: true
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]
