<template>
    <div class="">
        <p class="mb-1 grey--text font-weight-bold">
            Creator:
        </p>
        <div v-if="data.author">
            <a
                class="d-flex text-decoration-none black--text"
                :class="{'pointer-none' : !linkType}"
                :href="linkType"
                target="_blank"
            >
                <AvatarRolles
                    :access-status="data.author.type"
                    :firstname="data.author.firstname"
                    :lastname="data.author.lastname"
                    :avatar-url="data.author.avatar_url"
                    :avatar-fallback="data.author.avatar_fallback"
                    :is-accent="isByClient"
                    avatar-size="45"
                />
                <div class="ml-4">
                    <p class="mb-0 font-weight-bold">
                        {{ data.author.firstname }}
                        ({{ data.author.type }})
                    </p>
                    <p class="mb-0">
                        ID: {{ data.author.id }}
                    </p>
                </div>
            </a>
        </div>
        <div v-else>
            <p>Empty data</p>
        </div>
    </div>
</template>

<script>
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    components: {
        AvatarRolles
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        linkType() {
            if (this.data.author.type === 'Admin' || this.data.author.type === 'Lead') return false
            return `${this.data.respondent.type.toLowerCase() === 'client' ? '/hr' : ''}/${this.data.respondent.type.toLowerCase()}s/${this.data.respondent.id}`
        },
        isByClient() {
            if (this.data.author.type !== 'Admin') {
                return true
            }
            return false
        }
    }
}
</script>
