<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-btn
                color="accent"
                @click="openModal"
            >
                Create new variable
            </v-btn>

            <v-row class="mb-5" />

            <v-data-table
                :headers="tableHeader"
                :items="variables"
                item-key="id"
                :loading="isLoading"
                hide-default-footer
                calculate-widths
                class="mt-10"
            >
                <!-- eslint-disable-next-line -->
                <template v-slot:item.value="props">
                    <v-switch
                        v-if="props.item.type === 'boolean'"
                        color="success"
                        :input-value="+props.item.value === 1"
                        :label="props.item.key"
                        @change="save({value: +props.item.value === 1 ? '0': '1', key: props.item.key})"
                    />
                    <v-edit-dialog
                        v-else
                        :return-value.sync="props.item.value"
                        large
                        persistent
                        @save="save({value: props.item.value, key: props.item.key})"
                    >
                        <div class="input_variable">
                            <v-text-field
                                class="mb-0"
                                :label="props.item.key"
                                readonly
                                :value="props.item.value"
                                outlined
                                dense
                            />
                        </div>
                        <template v-slot:input>
                            <v-text-field
                                v-model="props.item.value"
                                class="mt-4 mb-0"
                                label="Value"
                                outlined
                                dense
                            />
                        </template>
                    </v-edit-dialog>
                </template>
            </v-data-table>
        </v-container>

        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :input-label="modal.inputLabel"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { eventBus } from '@/helpers/event-bus/'
import {
    TABLE_HEADER
} from '@/constants/Settings/index.js'
import CreateVariable from '@/components/shared/Modals/CreateVariable'

export default {
    components: {
        CreateVariable
    },
    data() {
        return {
            isLoading: true,
            variables: [],
            currentVariable: '',
            modal: {}
        }
    },
    computed: {
        tableHeader() {
            return TABLE_HEADER
        },
        modalComponent() {
            return this.modal?.name || ''
        }
    },
    async created() {
        await this.getSettings()
    },
    methods: {
        async getSettings() {
            try {
                this.isLoading = true
                const { data } = await Api.get('/api/settings/get-settings')
                this.variables = data.filter((item) => item.key !== 'project_types')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async save(payload) {
            await Api.post('/api/settings/set-default', { ...payload })
            await this.getSettings()
        },
        async onConfirmModal(payload) {
            await Api.post('/api/settings/set-default', { ...payload })
            await this.getSettings()
            this.modal = {}
        },
        openModal() {
            this.modal = {
                name: 'CreateVariable',
                success_message: 'Variable was created'
            }
        },
        onCloseModal() {
            this.modal = {}
        }
    }
}
</script>

<style lang="scss" >
    .input_variable{
        border-radius: 4px;
        width: 220px;
        position: relative;
        cursor: pointer;
        .v-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            transition: .3s all;
        }
        &:hover {
            .v-icon {
                opacity: 1;
            }
        }
    }
</style>
