<template>
    <div class="wrapper py-5 mails-viewer">
        <v-container
            fluid
            class="white rounded"
        >
            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                >
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mailing-list'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing List
                        </router-link>
                    </v-hover>
                    <!-- <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mails'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mail Remarketing
                        </router-link>
                    </v-hover> -->
                    <v-hover
                        v-slot="{ hover }"
                    >
                        <router-link
                            :to="{name: 'mailing-templates'}"
                            class="text-decoration-none ml-2 font-weight-medium pa-2 subtitle-1"
                            :class="hover ? 'accent--text text-decoration-underline' : 'black--text'"
                        >
                            Mailing Templates
                        </router-link>
                    </v-hover>
                </template>
            </the-main-filter>
            <v-data-table
                must-sort
                :headers="tableHeader"
                :items="getterMailsLogList"
                item-key="id"
                :loading="false"
                hide-default-footer
                calculate-widths
                class="mt-4"
                :server-items-length="getterMailsLogTotal"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:options="onSortTable"
            >
                <template #header="{}">
                    <thead
                        class="v-data-table-header"
                    >
                        <tr>
                            <th
                                v-for="h in tableHeaderTotal"
                                :key="h.value"
                            >
                                <template v-if="h.value === 'total'">
                                    <div class="d-flex align-center justify-end py-2">
                                        <div class="">
                                            <p class="mb-0 grey--text">
                                                Total:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterMailsLogTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterMailsLogTotal }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <!-- <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    /> -->
                    <tbody>
                        <template>
                            <tr
                                v-for="(item, index) in items"
                                :key="index"
                                class="hovered-row cursor--pointer"
                                @click="openModal('fetch', item.id)"
                            >
                                <!-- id -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.id }}
                                    </p>
                                </td>
                                <!-- avatar -->
                                <td class="td_index">
                                    <div class="d-flex align-center">
                                        <avatar-rolles
                                            access-status="admin"
                                            :firstname="item.admin.firstname"
                                            :lastname="item.admin.lastname"
                                            :avatar-url="item.admin.avatar_url"
                                            avatar-size="50"
                                        />
                                        <p class="mb-0 ml-3">
                                            <b>{{ item.admin.firstname }} {{ item.admin.lastname }}</b>
                                            <br>
                                            ID: <b>{{ item.admin.id }}</b>
                                        </p>
                                    </div>
                                </td>
                                <!-- address -->
                                <td class="td_index">
                                    <domain-favicon
                                        :domain="getDomain(item.address)"
                                        :tooltip-title="item.address"
                                        class="justify-start"
                                    />
                                </td>
                                <!-- recipients -->
                                <td
                                    class="td_index"
                                >
                                    <p
                                        v-for="(recipient, indexRecipient) in item.recipients"
                                        :key="indexRecipient"
                                        class="mb-0"
                                    >
                                        <span>{{ recipient }}</span>
                                    </p>
                                </td>
                                <!-- recipients_count -->
                                <td class="td_index">
                                    <p class="mb-0">
                                        {{ item.recipients_count }}
                                    </p>
                                </td>
                                <!-- DATE CREATE -->
                                <td>
                                    <div class="d-flex align-center">
                                        <p class="mb-0">
                                            {{ item.created_at && $date.fromWithText(item.created_at) }}
                                        </p>
                                        <information-tooltip>
                                            <div class="mb-1">
                                                <p class="mb-0 text-body-2">
                                                    Created:
                                                </p>
                                                <p class="mb-0 d-flex align-center  text-subtitle-2 font-weight-regular">
                                                    {{ item.created_at ? $date.fullDate(item.created_at) : '—' }}
                                                </p>
                                            </div>
                                        </information-tooltip>
                                    </div>
                                </td>
                                <!-- template -->
                                <td class="td_index">
                                    <div class="d-flex align-center">
                                        <span class="mb-0">
                                            {{ item.template.description }}
                                        </span>
                                        <v-icon
                                            class="ml-4"
                                            @click.stop="preview(item.template.id)"
                                            v-text="'mdi-eye'"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <table-loader
                        v-if="isLoading"
                    />
                    <table-skeleton
                        v-if="isLoading && getterMailsLogTotal === 0"
                        :per-page="10"
                        :column-count="tableHeaderLength"
                    />
                </template>
            </v-data-table>
            <div
                v-if="!getterMailsLogTotal && !isLoading "
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No logs found
                </p>
            </div>
            <pagination-with-perpage
                v-if="!getterMailsLogTotalsLoading"
                :list-lenght="getterMailsLogList.length"
                :last-page="getterMailsLogLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />
            <mailing-log-viewer
                v-if="showDetails"
                :id="currentItem"
                @close="closeDetails"
            />
            <mail-preview
                v-if="showPreviewModal"
                :title="templateTitle"
                :html="templateHtml"
                @closeModal="onClosePreviewModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';

import {
    ACTION_GET_MAILING_TEMPLATE,
    ACTION_GET_MAILS_LOG_LIST,
    ACTION_GET_MAILS_LOG_TOTALS
} from '@/store/modules/mailing/action-types';

import { eventBus } from '@/helpers/event-bus/'

import {
    MAILS_LOG_TABLE_HEADER, MAILS_LOG_TABLE_HEADER_TOTAL
} from '@/constants/MailingLog'

import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import InformationTooltip from '@/components/shared/InformationTooltip'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'
import TableSkeleton from '@/components/shared/Tables/TableSkeleton'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import MailingLogViewer from '@/components/SidePanels/MailingLogViewer.vue';
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import MailPreview from '@/components/shared/Modals/MailPreview'
import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';

const {
    mapActions: MapMailingAction
} = createNamespacedHelpers('mailing');

export default {
    components: {
        InformationTooltip,
        TheMainFilter,
        TableLoader,
        TableSkeleton,
        AvatarRolles,
        MailingLogViewer,
        DomainFavicon,
        MailPreview,
        PaginationWithPerpage
    },
    data() {
        return {
            list: [],
            isLoading: false,
            isLoadingPreviewHtml: false,
            showDetails: false,
            currentItem: null,
            showPreviewModal: false,
            templateHtml: '',
            templateTitle: '',
            search_fields: {
                datepicker: {
                    cols: 12,
                    value: 'datepicker'
                },
                domain: {
                    value: 'domain',
                    label: 'Domain',
                    list: [
                        {
                            text: 'All',
                            value: 'all'
                        },
                        {
                            text: 'customessaymeister.com',
                            value: 'customessaymeister.com'
                        },
                        {
                            text: 'dreamessays.com',
                            value: 'dreamessays.com'
                        },
                        {
                            text: 'solidpapers.com',
                            value: 'solidpapers.com'
                        },
                        {
                            text: 'genuinewriting.com',
                            value: 'genuinewriting.com'
                        },
                        {
                            text: 'professays.com',
                            value: 'professays.com'
                        },
                        {
                            text: 'paper.coach',
                            value: 'paper.coach'
                        }
                    ]
                },
                search_by: {
                    value: 'search_by',
                    label: 'Search by field',
                    list: [
                        {
                            text: 'Domain',
                            value: 'domain'
                        },
                        {
                            text: 'Priority',
                            value: 'priority'
                        },
                        {
                            text: 'Delay',
                            value: 'delay'
                        },
                        {
                            text: 'Description',
                            value: 'description'
                        },
                        {
                            text: 'Title',
                            value: 'title'
                        },
                        {
                            text: 'Subject',
                            value: 'subject'
                        }
                    ]
                }
                // sort_by: {
                //     value: 'sort_by',
                //     label: 'Sort by fields',
                //     list: [
                //         {
                //             text: 'Created at',
                //             value: 'created_at'
                //         },
                //         {
                //             text: 'Updated at',
                //             value: 'updated_at'
                //         },
                //         {
                //             text: 'ID',
                //             value: 'id'
                //         },
                //         {
                //             text: 'Domain',
                //             value: 'domain'
                //         },
                //         {
                //             text: 'Priority',
                //             value: 'priority'
                //         },
                //         {
                //             text: 'Delay',
                //             value: 'delay'
                //         },
                //         {
                //             text: 'Description',
                //             value: 'description'
                //         },
                //         {
                //             text: 'Title',
                //             value: 'title'
                //         },
                //         {
                //             text: 'Subject',
                //             value: 'subject'
                //         }
                //     ]
                // }
            },
            sort: {
                sort_by: 'created_at',
                sort_order: 'DESC'
            },
            isFirstLoad: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: ''
            }
        };
    },
    computed: {
        ...mapGetters('mailing', [
            'getterMailsLogList',
            'getterMailsLogLastPage',
            'getterMailsLogTotal',
            'getterMailsLogCurrentPage',
            'getterMailsLogTotalsLoading'
        ]),
        tableHeader() {
            return MAILS_LOG_TABLE_HEADER
        },
        tableHeaderTotal() {
            return MAILS_LOG_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return MAILS_LOG_TABLE_HEADER_TOTAL.length + 1
        },
        listLength() {
            return this.list.length
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'ASC'
        }
    },
    async created() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        })
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        await this.getMailsLogList()
        this.isFirstLoad = false
    },
    methods: {
        ...MapMailingAction({
            ACTION_GET_MAILS_LOG_LIST,
            ACTION_GET_MAILING_TEMPLATE,
            ACTION_GET_MAILS_LOG_TOTALS
        }),
        async getMailsLogList(with_total = true) {
            if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                this.$router.replace({ query: { ...this.searchOptions } })
            }
            this.isLoading = true
            try {
                const requestArr = [this[ACTION_GET_MAILS_LOG_LIST]({ ...this.searchOptions, mode: 'list' })]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_MAILS_LOG_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoading = false
            }
        },
        openModal(type, item) {
            switch (type) {
            case 'fetch':
                this.currentItem = item
                this.showDetails = true
                break;
            default:
                break;
            }
        },
        closeDetails() {
            this.currentItem = {}
            this.showDetails = false
        },
        getDomain(address) {
            const [, domain] = address.split('@mg.')
            return domain
        },
        async preview(id) {
            await this.getPreviewHtml(id)
            this.showPreviewModal = true
        },
        onClosePreviewModal() {
            this.showPreviewModal = false
        },
        async getPreviewHtml(id) {
            try {
                this.isLoadingPreviewHtml = true
                const { html, title, domain } = await this[ACTION_GET_MAILING_TEMPLATE]({ id })
                this.templateHtml = html
                this.templateTitle = `${title} for ${domain}`
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isLoadingPreviewHtml = false
            }
        },
        onFilterTable(payload) {
            const newObj = Object.entries(payload).reduce((acc, curr) => {
                acc[curr[0]] = this.searchOptions[curr[0]] || ''
                return acc
            }, {})
            if (JSON.stringify(payload) !== JSON.stringify(newObj)) {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
                this.getMailsLogList()
            }
        },
        async paginationPageEvent(e) {
            this.searchOptions.page = e
            await this.getMailsLogList(false)
        },
        async onSortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'created_at'
                this.sort.sort_order = e.sortDesc[0] ? 'ASC' : 'DESC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort,
                    page: 1
                }
                await this.getMailsLogList()
            }
        }
    }
}
</script>

<style lang="scss">
    .mails-viewer {
        .cursor--pointer {
            cursor: pointer;
        }
        &_window {
            width: 100%;
        }
        &_example {
            display: flex;
            justify-content: center;
            width: 100%;
            max-height: 600px;
            overflow-y: scroll;
        }
        &_editor {
            width: 100%;
            max-width: 60%;
            max-height: 580px;
            min-height: 580px;
            margin-left: 20px;
            overflow-y: scroll;
            flex-grow: 1;
            .v-text-field__details {
                display:  none;
            }
            .v-input__slot {
                margin-bottom: 0;
            }
        }
    }
</style>
