<template>
    <modal
        :show-modal="showModal"
        :is-show-btns="false"
        card-body-class="pb-2"
        @closeModal="onCloseModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-card class="d-flex justify-center mb-4">
                <div v-html="html" />
            </v-card>
            <div
                v-if="isLoading"
                class="loader-wrapper"
            >
                <table-loader />
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'

export default {
    components: {
        Modal,
        TableLoader
    },
    props: {
        title: {
            type: String
        },
        html: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showModal: true,
            isLoading: false
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        }
    }
}
</script>

<style lang="scss">
    .loader-wrapper {
        min-height: 300px;
        width: 100%;
    }
</style>
