<template>
    <v-container
        fluid
        class="py-5 ticket-details"
    >
        <v-row>
            <v-col cols="12">
                <v-card
                    elevation="2"
                    class="px-8 py-8"
                >
                    <div class="d-flex align-center mb-5">
                        <p class="mb-0 text-subtitle-1">
                            «{{ ticket.subject }}»
                        </p>
                        <status-colored
                            :type="ticket.status_support"
                            class="ml-5"
                        />
                        <div class="ml-auto">
                            <template v-for="toolbar in supportActions">
                                <v-tooltip
                                    :key="toolbar.name"
                                    top
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            tile
                                            color="rgba(255, 0, 0, 0)"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="actionRow(toolbar)"
                                        >
                                            <v-icon>
                                                {{ toolbar.icon }}
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ toolbar.text }}</span>
                                </v-tooltip>
                            </template>
                        </div>
                    </div>
                    <v-row>
                        <v-col
                            cols="2"
                            class="flex-grow-0 mr-2"
                        >
                            <p class="mb-0 grey--text font-weight-bold">
                                Owner:
                            </p>
                            <template v-if="getterChat && getterChat.length > 0">
                                <div class="d-flex">
                                    <avatar-rolles
                                        :access-status="getLastMessageData.type"
                                        :firstname="getLastMessageData.firstname"
                                        :lastname="getLastMessageData.firstname"
                                        :avatar-url="getLastMessageData.avatar_url"
                                        :avatar-fallback="getLastMessageData.avatar_fallback"
                                        avatar-size="45"
                                    />
                                    <div class="ml-4">
                                        <p class="mb-0 font-weight-bold">
                                            {{ getLastMessageData.firstname }}
                                            ({{ getLastMessageData.type }})
                                        </p>
                                        <p>
                                            ID: {{ getLastMessageData.id }}
                                        </p>
                                    </div>
                                </div>
                            </template>
                        </v-col>
                        <v-col
                            cols="2"
                            class="flex-grow-0 mr-2"
                        >
                            <div class="">
                                <p class="mb-1 grey--text font-weight-bold">
                                    Respondent:
                                </p>
                                <div v-if="ticket.respondent">
                                    <a
                                        class="d-flex text-decoration-none black--text"
                                        :href="linkType(ticket)"
                                        target="_blank"
                                    >
                                        <avatar-rolles
                                            access-status="client"
                                            :firstname="ticket.respondent.firstname"
                                            :lastname="ticket.respondent.lastname"
                                            :avatar-url="ticket.respondent.avatar_url"
                                            :avatar-fallback="ticket.respondent.avatar_fallback"
                                            avatar-size="45"
                                        />
                                        <div class="ml-4">
                                            <p class="mb-0 font-weight-bold">
                                                {{ ticket.respondent.firstname }}
                                                ({{ ticket.respondent.type }})
                                            </p>
                                            <p class="mb-0">
                                                ID: {{ ticket.respondent.id }}
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="ticket.ticketid "
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Ticket ID:
                            </p>
                            <div>
                                <!-- <v-icon
                                    color="primary"
                                    class="mr-2"
                                >
                                    mdi-barcode
                                </v-icon> -->
                                {{ ticket.ticketid }}
                                <copy-to-click-board
                                    class="d-inline-flex"
                                    :copy-string="ticket.ticketid"
                                    success-mesage=" The ticket ID is copied."
                                    icon
                                />
                            </div>
                        </v-col>
                        <v-col
                            v-if="ticket.orderid "
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Order ID:
                            </p>
                            <div>
                                <router-link
                                    :to="{
                                        name: 'order-details',
                                        params: {
                                            id: ticket.orderid
                                        }
                                    }"
                                    class="black--text text-decoration-none"
                                >
                                    {{ ticket.orderid }}
                                </router-link>
                                <copy-to-click-board
                                    class="d-inline-flex"
                                    :copy-string="ticket.orderid "
                                    success-mesage=" The order ID is copied."
                                    icon
                                />
                            </div>
                        </v-col>
                        <v-col
                            v-if="ticket.domain"
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p
                                class="mb-0 grey--text"
                            >
                                Domain:
                            </p>
                            <div class="d-flex align-center">
                                <domain-favicon
                                    :domain="ticket.domain"
                                    class="flex-shrink-0"
                                />
                                <p class="mb-0">
                                    {{ ticket.domain }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Created:
                            </p>
                            <div class="d-flex align-center">
                                <v-icon
                                    color="primary"
                                    class="mr-2"
                                >
                                    mdi-calendar-blank
                                </v-icon>
                                <p class="mb-0">
                                    {{ $date.humanizeDate(ticket.created_at) }}
                                </p>
                            </div>
                        </v-col>
                        <v-col
                            v-if="getterChat && getterChat.length > 0"
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Last reply:
                            </p>
                            <div class="d-flex align-center">
                                <v-icon
                                    color="primary"
                                    class="mr-2"
                                >
                                    mdi-calendar-blank
                                </v-icon>
                                <p
                                    v-if="getterChat"
                                    class="mb-0"
                                >
                                    {{ $date.humanizeDate(getterChat[getterChat.length - 1].created_at) }}
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Subject:
                            </p>
                            <div class="d-flex align-center">
                                <status-colored
                                    :type="ticket.msgtype"
                                />
                            </div>
                        </v-col>
                        <v-col
                            v-if="ticket.msgcategory"
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Category:
                            </p>
                            <div class="d-flex align-center font-weight-semibold">
                                <status-colored
                                    :type="getCategoryHumanize"
                                />
                            </div>
                        </v-col>
                        <v-col
                            v-if="ticket.msgresolution"
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Resolution:
                            </p>
                            <div class="d-flex align-center font-weight-semibold">
                                <status-colored
                                    :type="getResolutionHumanize"
                                />
                            </div>
                        </v-col>
                        <v-col
                            lg="2"
                            md="3"
                            cols="6"
                        >
                            <p class="mb-0 grey--text">
                                Check callback:
                            </p>
                            <p
                                class="mb-0"
                                :class="!ticket.chk_callback ? 'error--text' : 'success--text'"
                            >
                                {{ ticket.chk_callback ? 'Yes' : 'No' }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-card
            elevation="2"
            class="mt-5 mb-4"
        >
            <v-row>
                <v-col cols="12">
                    <div class="d-flex px-8 align-center">
                        <p class="mb-0 text-subtitle-1">
                            {{ getType.toUpperCase() }}
                        </p>
                        <span
                            v-if="getterChat"
                            class="counter"
                        >{{ getterChat.length }}</span>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    class="py-0"
                >
                    <v-divider />
                </v-col>
                <v-col
                    cols="12"
                    class="pt-0 chat--container"
                >
                    <chat
                        v-if="getterChat && getterChat.length"
                        :chat="getterChat"
                        :loading="isSending"
                        :owner-roles="['Admin']"
                        :typing-message="typingMessage"
                        @typing="typingListener"
                        @sendChatMessage="onSendChatMessage"
                    />
                </v-col>
            </v-row>
        </v-card>
        <component
            :is="modalComponent"
            :title="modal.title"
            :description="modal.description"
            :input-label="modal.inputLabel"
            @closeModal="onCloseModal"
            @confirmModal="onConfirmModal"
        />
    </v-container>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'

import { eventBus } from '@/helpers/event-bus/'
import Chat from '@/components/Chat/Chat'
import {
    ACTION_CLIENT_TRANSFER_TICKET,
    ACTION_CLOSE_TICKET,
    ACTION_GET_TICKET,
    ACTION_SEND_MESSAGE,
    ACTION_WRITER_CLOSE_TICKET,
    ACTION_WRITER_SEND_MESSAGE,
    ACTION_WRITER_TRANSFER_TICKET,
    ACTION_GET_SUPPORT_CHAT
} from '@/store/modules/support/action-types';

import {
    ACTION_CREATE_BOOKMARK
} from '@/store/modules/bookmarks/action-types';

import ModalTransfer from '@/components/shared/Modals/ModalTransfer'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import ModalTextarea from '@/components/shared/Modals/ModalTextarea'
import CopyToClickBoard from '@/components/shared/Helpers/CopyToClickBoard'

import StatusColored from '@/components/shared/Helpers/StatusColored'

import ClientAvatar from '@/components/shared/Avatars/ClientAvatar'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

import {
    problems, resolutions
} from '@/constants/Support/support-types'

import {
    SUPPORT_CARD_ACTIONS
} from '@/constants/Support'
import { SET_SUPPORT_CHAT_MESSAGE } from '@/store/modules/support/mutation-types';
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon';

const {
    mapActions: mapAdminActions,
    mapMutations: mapSupportMutations
} = createNamespacedHelpers('support');

const {
    mapActions: mapBookmarkActions
} = createNamespacedHelpers('bookmarks');

export default {
    components: {
        StatusColored,
        Chat,
        ModalAlert,
        ModalTransfer,
        ModalTextarea,
        CopyToClickBoard,
        DomainFavicon,
        ClientAvatar,
        WriterAvatar,
        AvatarRolles
    },
    data() {
        return {
            ticket: {},
            modal: {},
            actionName: '',
            isSending: false,
            typing: false,
            typingMessage: '',
            messages: []
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterMainInfo'
        ]),
        ...mapGetters('support', [
            'getterChat'
        ]),
        modalComponent() {
            return this.modal?.name || ''
        },
        getType() {
            const { type } = this.$route.params
            return type || ''
        },
        getAdminFullname() {
            return `${this.getterMainInfo.firstname} ${this.getterMainInfo.lastname}`
        },
        getClientFullname() {
            if (this.ticket.firstname) {
                return `${this.ticket.firstname} ${this.ticket.lastname}`
            } if (this.ticket.fullname) {
                return this.ticket.fullname
            }
            return this.ticket.email
        },
        supportActions() {
            return SUPPORT_CARD_ACTIONS
        },
        getCategoryHumanize() {
            const result = problems.find((item) => this.ticket.msgcategory === item.value)
            return result ? result.text : ''
        },
        getResolutionHumanize() {
            const result = resolutions.find((item) => this.ticket.msgresolution === item.value)
            return result ? result.text : ''
        },
        getLastMessageData() {
            const item = this.getterChat[0]
            return item.author
        }
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticket.ticketid
            })
        }
    },
    metaInfo() {
        return {
            title: `${this.$route.params.id} | Support ticket`
        }
    },
    async beforeRouteUpdate(to, from, next) {
        const { id, type } = to.params
        const data = await this[ACTION_GET_TICKET](
            {
                id,
                type
            }
        )
        this.ticket = data
        next()
    },
    async activated() {
        const { id, type } = this.$route.params
        const data = await this[ACTION_GET_TICKET]({ id, type })
        this.ticket = data
        await this.getMessages()
        this.socketConnect()
    },
    deactivated() {
        this.$socket.emit('support:unsubscribe', this.ticket.ticketid)
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_TICKET,
            ACTION_SEND_MESSAGE,
            ACTION_CLOSE_TICKET,
            ACTION_WRITER_CLOSE_TICKET,
            ACTION_WRITER_SEND_MESSAGE,
            ACTION_CLIENT_TRANSFER_TICKET,
            ACTION_WRITER_TRANSFER_TICKET,
            ACTION_GET_SUPPORT_CHAT
        }),
        ...mapBookmarkActions({
            ACTION_CREATE_BOOKMARK
        }),
        ...mapSupportMutations([
            SET_SUPPORT_CHAT_MESSAGE
        ]),
        typingListener(value) {
            this.typing = value
        },
        async onSendChatMessage(payload) {
            this.isSending = true
            try {
                if (this.getType === 'client') {
                    await this[ACTION_SEND_MESSAGE]({ payload, item: this.ticket })
                } else {
                    await this[ACTION_WRITER_SEND_MESSAGE]({ payload, item: this.ticket })
                }
                eventBus.$emit('showSnackBar', 'Message is sent', 'success')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isSending = false
            }
        },
        async closeTicket() {
            if (this.getType === 'client') {
                await this[ACTION_CLOSE_TICKET]({ id: this.ticket.ticketid })
                this.ticket.status_support = 'CLOSED'
            } else {
                await this[ACTION_WRITER_CLOSE_TICKET]({ id: this.ticket.ticketid })
                this.ticket.status_support = 'CLOSED'
            }
        },
        async createBookmark(payload) {
            await this[ACTION_CREATE_BOOKMARK]({ ...payload, referenceids: [this.ticket.ticketid] })
        },
        async transferTicket({ admin_id }) {
            if (this.getType === 'client') {
                await this[ACTION_CLIENT_TRANSFER_TICKET]({ admin_id, ticketid: this.ticket.ticketid })
            } else {
                await this[ACTION_WRITER_TRANSFER_TICKET]({ admin_id, ticketid: this.ticket.ticketid })
            }
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'close_ticket':
                    await this.closeTicket()
                    break
                case 'transfer':
                    await this.transferTicket(payload)
                    break
                case 'bookmark':
                    await this.createBookmark(payload)
                    break
                default:
                    break
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        linkType(item) {
            if (item.respondent_model === 'Admin' || item.respondent.type === 'Admin') return false
            return `${item.respondent.type.toLowerCase() === 'client' ? '/hr' : ''}/${item.respondent.type.toLowerCase()}s/${item.respondent.id}`
        },
        actionRow(action) {
            this.actionName = action.name
            this.modal = action.modal
        },
        onCloseModal() {
            this.actionName = ''
            this.modal = {}
        },
        socketConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticket.ticketid })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainInfo.id) return
                this[SET_SUPPORT_CHAT_MESSAGE]({ ...data, ticketid: this.ticketId })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Admin') return
                this.typingMessage = data.message
            })

            this.$socket.on('disconnect', () => {
                this.socketConnect()
            });
        },
        async getMessages() {
            try {
                await this[ACTION_GET_SUPPORT_CHAT]({ id: this.ticket.ticketid, isWriter: this.getType !== 'client' })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
    .counter{
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: #F2F2F2;
        border-radius: 4px;
        text-align: center;
        margin-left: 10px;
        font-weight: bold;
    }
    .ticket-details{
        .chat_items {
            // max-height: 500px;
            height: 100%;
            overflow-x: hidden;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
        }
    }
    .chat--container{
        max-height: calc(100vh - 170px);
    }
</style>
