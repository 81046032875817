export const FORUM_TABLE_HEAD = [
    {
        text: 'In-x',
        value: 'index',
        width: '60px',
        align: 'center',
        sortable: false
    },
    {
        text: 'Prt.',
        value: 'status_priority',
        width: '70px',
        align: 'center'
    },
    {
        text: 'Created',
        value: 'ts_issued',
        sortable: true,
        width: '220px'
    },
    {
        text: 'Subject',
        sortable: true,
        value: 'title'
    },
    {
        text: 'Status',
        value: 'status',
        sortable: true,
        width: '50px'
    }
]
