<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Create variable</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-text-field
                    v-model="key"
                    outlined
                    color="accent"
                    class="my-3"
                    :label="'Key'"
                    hide-details
                    :rules="valid_key"
                    required
                />
                <v-select
                    v-model="type"
                    :items="types"
                    label="Type"
                    :rules="valid_type"
                    outlined
                    hide-details
                />
                <v-text-field
                    v-model="value"
                    outlined
                    color="accent"
                    class="my-3"
                    :label="'Value'"
                    hide-details
                    :rules="valid_value"
                    required
                />
            </v-form>
        </template>
    </modal>
</template>

<script>
import Modal from '@/components/shared/Modals/Modal'

export default {
    components: {
        Modal
    },
    data() {
        return {
            valid: false,
            showModal: true,
            key: '',
            valid_key: [
                (v) => !!v || 'Key is required'
            ],
            value: '',
            valid_value: [
                (v) => !!v || 'Value is required'
            ],
            type: '',
            valid_type: [
                (v) => !!v || 'Type is required'
            ]
        }
    },
    computed: {
        types() {
            return [
                {
                    text: 'Boolean',
                    value: 'boolean'
                },
                {
                    text: 'String',
                    value: 'string'
                }
            ]
        }
    },
    methods: {
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        key: this.key,
                        value: this.value,
                        type: this.type
                    })
                }
            })
        }
    }
}
</script>
