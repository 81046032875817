<template>
    <modal
        :show-modal="showModal"
        :loading="loading"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>{{ title }}</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-select
                    v-model="domain"
                    :items="mailingDomains"
                    label="Domain"
                    class="my-3"
                    outlined
                    :rules="[rules.required]"
                    required
                    hide-details
                />
                <v-text-field
                    v-model="mailing_name"
                    outlined
                    color="accent"
                    class="my-3"
                    label="Name"
                    hide-details
                    :rules="[rules.required]"
                    required
                />
                <v-textarea
                    v-model="mailing_description"
                    :rules="[rules.required]"
                    outlined
                    color="accent"
                    class="my-3"
                    label="Description"
                    hide-details
                    required
                />
            </v-form>
        </template>
    </modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import Modal from '@/components/shared/Modals/Modal'
import {
    ACTION_GET_DOMAINS
} from '@/store/modules/config/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('config');
export default {
    name: 'ModalCreateMailing',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            valid: false,
            showModal: true,
            mailing_name: '',
            mailing_description: '',
            domain: '',
            rules: {
                required: (value) => !!value || 'Required.'
            }
        }
    },
    computed: {
        ...mapGetters('config', [
            'getterDomains'
        ]),
        mailingDomains() {
            return this.getterDomains.map(({ text, value }) => ({
                text: `mg.${text}`,
                value: `mg.${value}`
            }))
        }
    },
    async created() {
        await this.getDomains()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_DOMAINS
        }),
        async getDomains() {
            try {
                await this[ACTION_GET_DOMAINS]()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        name: this.mailing_name,
                        description: this.mailing_description,
                        domain: this.domain
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>
