<template>
    <div
        class="wrapper py-5 mails-viewer"
    >
        <v-container
            fluid
            class="mails-viewer_window white rounded d-flex align-start justify-space-between"
        >
            <v-form
                ref="form"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-model="domain"
                    name="domain"
                    label="Domain"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="title"
                    name="title"
                    label="Title"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="description"
                    name="description"
                    label="Description"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="subject"
                    name="subject"
                    label="Subject"
                    outlined
                    color="accent"
                    clearable
                />
                <v-text-field
                    v-model="delay"
                    name="delay"
                    label="Delay"
                    outlined
                    color="accent"
                    clearable
                    type="number"
                />
                <v-text-field
                    v-model="priority"
                    name="priority"
                    label="Priority"
                    outlined
                    color="accent"
                    clearable
                    type="number"
                />
                <div class="d-flex">
                    <v-btn
                        color="primary"
                        height="56px"
                        width="200px"
                        class=""
                        :loading="loadingSubmit"
                        :disabled="!html"
                        type="submit"
                    >
                        Submit
                    </v-btn>
                    <v-btn
                        v-if="hasId"
                        color="primary"
                        height="56px"
                        width="200px"
                        class="ml-4"
                        :loading="isLoading"
                        :disabled="!html"
                        @click="openModal()"
                    >
                        Preview
                    </v-btn>
                </div>
            </v-form>
            <div class="mails-viewer_editor">
                <v-form>
                    <v-textarea
                        v-model="html"
                        name="Editor"
                        filled
                        label="Editor"
                        auto-grow
                    />
                </v-form>
            </div>
        </v-container>

        <v-container
            fluid
            class="mails-viewer_window white rounded d-flex align-start justify-space-between"
        >
            <v-card class="mails-viewer_example">
                <div
                    v-html="html"
                />
            </v-card>
            <mail-preview
                v-if="showModal"
                :id="id"
                :title="title"
                :html="templateHtml"
                @closeModal="onCloseModal"
            />
        </v-container>
        <table-loader
            v-if="isLoading"
        />
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js'
import { createNamespacedHelpers } from 'vuex';
import {
    ACTION_GET_SELECT_EMAIL,
    ACTION_CREATE_OR_UPDATE
} from '@/store/modules/emailViewer/action-types';

import { eventBus } from '@/helpers/event-bus/'
import {
    MAILS_VIEWER_TABLE_HEADER, MAILS_VIEWER_TABLE_HEADER_TOTAL
} from '@/constants/MailsViewer'

import MailPreview from '@/components/shared/Modals/MailPreview'
import TableLoader from '@/components/shared/Tables/TableLoader.vue'

const {
    mapActions: mapFeedbacksActions
} = createNamespacedHelpers('emailViewer');

export default {
    components: {
        MailPreview,
        TableLoader
    },
    data() {
        return {
            html: null,
            id: null,
            domain: null,
            title: null,
            description: null,
            subject: null,
            delay: null,
            priority: null,
            isLoading: false,
            hasId: false,
            showModal: false,
            templateHtml: null,
            loadingSubmit: false
        };
    },
    computed: {
        formData() {
            const {
                html, domain, title, description, subject, delay, priority
            } = this
            return {
                html, domain, title, description, subject, delay, priority
            }
        },
        tableHeader() {
            return MAILS_VIEWER_TABLE_HEADER
        },
        tableHeaderTotal() {
            return MAILS_VIEWER_TABLE_HEADER_TOTAL
        },
        tableHeaderLength() {
            return MAILS_VIEWER_TABLE_HEADER.length + 1
        },
        modalComponent() {
            return this.modal || ''
        }
    },
    created() {
        if (this.$route.params.id) {
            this.hasId = true
            this.getMail()
        }
    },
    methods: {
        ...mapFeedbacksActions({
            ACTION_CREATE_OR_UPDATE,
            ACTION_GET_SELECT_EMAIL
        }),
        async getMail() {
            this.isLoading = true
            try {
                const data = await this[ACTION_GET_SELECT_EMAIL]({ id: this.$route.params.id })
                this.html = data.html
                this.id = data.id
                this.domain = data.domain
                this.title = data.title
                this.description = data.description
                this.subject = data.subject
                this.delay = data.delay
                this.priority = data.priority
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error')
            } finally {
                this.isLoading = false
            }
        },
        async openModal() {
            await this.getPreview()
            this.showModal = true
        },
        async submit() {
            try {
                this.loadingSubmit = true
                if (!this.hasId) {
                    await this[ACTION_CREATE_OR_UPDATE](this.formData)
                } else {
                    await this[ACTION_CREATE_OR_UPDATE]({ ...this.formData, id: this.id })
                }
                eventBus.$emit('showSnackBar', 'Letter has been created', 'success')
                this.$router.push('/settings/mails')
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error')
            } finally {
                this.loadingSubmit = false
            }
        },
        async getPreview() {
            try {
                const { data } = await Api.get('/api/mail/remarketing/render', { id: this.id })
                this.templateHtml = data
            } catch (e) {
                eventBus.$emit('showSnackBar', e, 'error')
            }
        },
        onCloseModal() {
            this.showModal = false
        }
    }
}
</script>

<!-- <style lang="scss">
    .mails-viewer {
        &_input {
            width: 33%;
        }
    }
</style> -->
